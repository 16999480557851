@import '../../framework/styleguide.scss';
@import '../../framework/common.scss';

.error-page {
    width: 1440px;
    max-width: 100%;
    margin: 0 auto;

    &__content {
        padding: 57px 20px;
        overflow: hidden;

        @include breakpoint(large) {
            padding: 86px 10.416666667%;
        }
    }

    &__logo {
        display: flex;
        margin-left: auto;
        margin-right: auto;
        width: 170px;
    }

    &__inner {
        border: 1px solid rgba($white, 0.15);
        border-left:none;
        border-right:none;
        padding:62px 0 30px;
    }

    &__heading {
        color:$white;
        font-size:3.2rem;
        font-weight: 300;
        margin-bottom: 3.2rem;
        text-align: center;
    }

    p {
        color:$white;
        margin-bottom: 3.2rem;
        text-align: center;
    }

    &__buttons {
        text-align: center;
    }

    &__link-btn {
        border:solid 1px #79bafa;
        border-radius: 4px;
        color: #79bafa;
        display: inline-block;
        font-size:1.4rem;
        margin:0 8px 30px;
        padding:10px 14px;
        text-decoration: none;
        transition: all 0.3s ease;

        &:hover {
            border:solid 1px $white;
            color:$white;
        }
    }

    &__contact {
        color:$white;
        font-size: 1.4rem;
        list-style: none;
        margin:100px 0 0;
        text-align: center;

        li {
            display: inline-block;
            &:after {
                content: '•';
                display: inline-block;
                margin:0 10px;
            }

            &:last-child {
                &:after {
                    display: none;
                }
            }

            a {
                color:$white;
            }
        }
    }
}
