@import '../../framework/styleguide.scss';
.tooltip {

    &__icon {
        align-self: center;
        background-image: url("../../images/info-icon.svg");
        background-size: 16px 16px;
        background-repeat: no-repeat;
        cursor: pointer;
        display: inline-block;
        height: 16px;
        margin-left: 5px;
        margin-top: 2px;
        width: 16px;
    }
    

    &__popup {
        background-color: $white;
        border-radius: 5px 5px 5px 0px;
        box-shadow: rgba(35, 47, 53, 0.14) 0px 2px 8px 6px;
        display: none;
        font-size: 1.2rem;
        left:0;
        position: absolute;
        width:280px;
        z-index: 3;
        padding: 15px 10px;
        transform: translateY(calc(-100%));
        transition: all 0.3s ease;
        opacity: 0;
        visibility: hidden;

        &:after {
            content: "";
            position: absolute;
            bottom: -11px;
            left: 0;
            width: 0px;
            height: 0px;
            border-left: 0px solid transparent;
            border-right: 13px solid transparent;
            border-top: 13px solid #fff;   
        }
        

        p {
            font-size: 1.2rem;
            line-height: 1.6;
            margin-bottom: 1.2rem;
        }

        ul {
            padding-left:18px;
        }

        .filter-item--is-open &,
        .compare-products__product-field & {
            display: block;
        }

        .tooltip--is-open & {
            opacity: 1;
            transform: translateY(calc(-100% - 42px));
            visibility: visible;
        }
    }
    
    &__popup-heading {
        font-size: 1.2rem;
        font-weight: bold;
    }
}