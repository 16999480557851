@import '../../framework/styleguide.scss';
@import '../../framework/common.scss';

.feedback {
    &__button {
        background: $white;
        background-image: url('../../images/speech.svg');
        background-repeat: no-repeat;
        background-position:10px center;
        border:solid 1px $very-light-pink;
        border-radius: 3px;
        cursor:pointer;
        display: block;
        font-size:1.5rem;
        font-weight: normal;
        padding: 9px 12px 9px 40px;
        text-align: left;
        transition: box-shadow 0.2s ease;
        width:100%;

        @include breakpoint(large) {
            display: inline-block;
            margin-left: 15px;
            width:auto;
        }

        &:hover,
        &:active,
        &:focus {
            box-shadow: 0 0 16px 0 rgba($black, 0.2);
        }
    }

    &__content {
        text-align: left;
        width:100%;
    }

    &__select,
    &__textarea {
        background: $white;
        border-radius: 3px;
        border: none;
        font-size: 1.6rem;
        padding: 15px;
        width: 100%;
    }

    &__input,
    &__select,
    &__textarea {
        margin-top: 1.2rem;
        margin-bottom: 2.2rem;
        max-width:343px;
    }

    &__textarea {
        display: block;
        height:100px;
        max-width: 100%;
    }

    &__label {
        display: block;
        text-align: left;
        width:100%;
    }

    &__overlay {
        .overlay__content {
            align-items: flex-start;
            justify-content: left;
            max-width:500px;
            width:100%;
            padding:0 10px;
        }

        .footer__button {
            margin-bottom:100px;   
            margin-top:30px;
            max-width:343px;
            width:100%;
        }
    }
}