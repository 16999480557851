@import '../../framework/styleguide.scss';
@import '../../framework/helpers.scss';
@import '../../framework/common.scss';

/* GET STARTED BENEFITS */
.onboard {
    background: $off-white;

    &__wrapper {
        @include fit-content();
    }

    &__wrapper-inner {
        @include content-padding();
        
        padding-bottom: 3rem;
        padding-top: 2.7rem;

        @include breakpoint(medium) {
            padding-bottom: 5.3rem;
            padding-top: 8.6rem;
        }
    }

    &__preamble {
        width: 508px;
        max-width: 100%;

        @include preamble();
    }

    &__benefits-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin-top: 3.6rem;
        justify-content: space-between;

        @include breakpoint(medium) {
            display: flex;
            flex-wrap: wrap;
            margin: 7rem -2.5% 0 -2.5%;
            justify-content: flex-start;
        }
    }

    &__benefit {
        display: flex;
        flex: 0 0 100%;
        margin-bottom: 2.7rem;

        @include breakpoint(medium) {
            flex: 0 0 20%;
            margin: 0 2.5% 3.3rem 2.5%;
        }

        //WordPress
        .block-editor & {
            position: relative;

            button {
                display: none;
                bottom:0;
                position: absolute;
            }

            &:hover {
                button {
                    display: block;
                }
            }
        }
    }

    &__content {
        width: 100%;

        @include breakpoint(medium) {
            width: 20rem;
            max-width: 100%;
        }

        h3 {
            font-weight: 600;
        }

        p {
            margin: 0;
        }
    }
    
    .checkmark-icon:before {
        margin-right: 1rem;
        margin-top: 2px;
        content: "";
        display: flex;
        width: 21px;
        height: 21px;
        align-self: center;
        background-size: 21px 21px;
        background-image: url("../../images/checkmark.svg");
        background-repeat: no-repeat;
      }
      
}