@import '../../framework/helpers.scss';
@import '../../framework/common.scss';
@import '../../framework/styleguide.scss';

.newsroom-contact {
    background-color: $off-white;
    text-align: center;

    @include breakpoint(large) {
        text-align: left;
        position: relative;
        z-index: 1;
        overflow: hidden;
    }

    &__bg-color {
        display: none;

        @include breakpoint(large) {
            width: 50%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 50%;
            z-index: 2;
            display: block;
        }
    }

    &__wrapper-outer {
        @include fit-content();

        @include breakpoint(large) {
            display: flex;
            align-items: stretch;
        }
    }

    &__wrapper {
        @include content-padding();

        @include breakpoint(large) {
            .newsroom-contact__business-info & {
                padding-left: 20.833333333%;
            }

            .newsroom-contact__business-contact & {
                padding-left: 13.75%;
            }
        }

       
    }

    &__business-contact {
        h2,
        p,
        a {
            color: $white;
        }

        a {
            text-decoration: none;
        }

        .newsroom-contact__wrapper--no-bg {
            h2,
            p,
            a {
                color:$text-black;
            }
        }

        img {
            background: $white;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
            border-radius: 3px;
            padding: 1.8rem 1.5rem;
            width: 19rem;
            max-width: 100%;
            height: auto;
        }
    }

    &__business-contact,
    &__business-info {
        @include breakpoint(large) {
            width: 50%;
            z-index: 3;
        }
        
    }

    &--no-desc {
        .newsroom-contact {
            &__business-contact {
                width:100%;

                .newsroom-contact__wrapper {
                    padding-left:10.416666667%;
                }
            }
            
            &__business-info {
                display: none;
            }

            &__bg-color {
                left:0;
                width:100%;
            }
        }
    }
}