@import '../../framework/common.scss';
@import '../../framework/styleguide.scss';
.grid-view-toggle {
    cursor: pointer;
    width: 18px;
    height: 17px;
    background-color: transparent;
    background-size: 18px 17px;
    background-repeat: no-repeat;
    border: none;
    align-self: center;
    display: none;
    opacity: 0.29;

    @include breakpoint(xmedium) {
        display: inline-block;
    }    

    &--grid-view {
        background-image: url("../../images/grid-view-icon.svg");
        margin-right: 1.4rem;
    }

    &--list-view {
        background-image: url("../../images/list-view-icon.png");
        margin-right: 19.4rem;

        .newsroom-toggle-products & {
            margin-right: 0;
        }
    }

    &--is-active {
        opacity: 1;
    }
}

/* SEARCH PAGE FILTER BUTTONS */

.filter-buttons {
    margin-top: 2.4rem;
    padding-bottom: 2.4rem;
    border-bottom: 1px solid $very-light-pink-four;
    display: flex;
    align-content: center;
    font-size: 1.4rem;

    &__button-small {
        border-radius: 3px;
    }

    a {
        text-decoration: none;
    }

    &__filter-trigger,
    &__watch-search {
      background-color: transparent;
      cursor: pointer;
      display: flex;
      font-size: 100%;
      font-weight: normal;
      text-align: center;
      justify-content: center;
      margin-right: 1.4rem;
      padding: 13px 14px 14px 14px !important;

      &:before {
        content: "";
        margin-right: 0.7rem;
        align-self: center;
        background-repeat: no-repeat;
      }
    }

    &__filter-trigger {
        position: relative;
        &:before {
            background-image: url("../../images/filter-icon.svg");
            width: 13px;
            height: 13px;
            background-size: 13px 13px;
        }
    }

    &__watch-search {
        &:before {
            background-image: url("../../images/star-icon.svg");
            width: 14px;
            height: 13px;
            background-size: 14px 13px;
        }
    }

    &__filter-counter {
        position: absolute;
        width: 20px;
        height: 20px;
        top: -10px;
        right: -10px;
        background-color: #e44b14;
        color: #fff;
        font-size: 1.1rem;
        font-weight: 600;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 0;
        transform: scale(0, 0);

        &--has-values {
            transition: transform 0.4s ease-in-out;
            transform: scale(1, 1);
            visibility: visible;
        }

        &--is-changing {
            animation: boing 500ms ease-in-out;
        }
    }
}


@keyframes boing {
    15%, 40%, 75%, 100% {
        transform-origin: center center;
    }
    15% {
        transform: scale(1.3, 1.2);
    }
    40% {
        transform: scale(0.8, 0.8);
    }
    75% {
        transform: scale(1.15, 1.1);
    }
    100% {
        transform: scale(1, 1);
    }
  }