@import "../../framework/common.scss";
@import "../../framework/helpers.scss";
@import "../../framework/styleguide.scss";
.newsroom-facets {
    margin-bottom: 3.2rem;
    &__list {
        display: inline-block;
        list-style: none;

        li {
            display: inline-block;
            font-weight: 600;
            font-size: 1.3rem;
            color: $brown-grey-two;
            letter-spacing: 1px;
            text-transform: uppercase;
            margin-right:10px;
        }
    }
    
    &__list-item {
        background-color: transparent;
        border: none;
        color:$darkish-blue;
        cursor: pointer;
        padding:0;
        text-transform: uppercase;
        text-decoration: underline;

        &--selected {
            color:$reddish-orange;
        }
    }
}