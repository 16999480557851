@import '../../framework/styleguide.scss';
@import '../../framework/common.scss';
@import '../../framework/helpers.scss';

.changed {
    background-color: $off-white;
    
    min-height: calc(100vh - 60px);

    @include breakpoint(large) {
        min-height: calc(100vh - 90px);
    }

    &__wrapper {
        @include fit-content();    
    }

    &__wrapper-inner {
        padding: 57px 20px 0;

        @include breakpoint(large) {
            padding: 57px 10.416666667% 0;
        }
    }

    &__heading {
        border-bottom:solid 1px #e4e4e4;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 3rem;
        padding-bottom: 3.8rem;

        h1 {
            margin-bottom:0;
        }

        a {
            color:$dabas-blue;
            text-decoration: none;
            img {
                margin-right:6px;
                position: relative;
                top:2px;
            }

            &:hover {
                span {
                    text-decoration: underline;
                }
            }
        }
    }

    &__heading-controls {
        display: flex;
        flex-direction: column;
    }

    &__edit-search {
        background-image: url('../../images/edit-watched-item.svg');
        background-repeat: no-repeat;
        background-position: left 2px;
        background-size: 20px 20px;
        margin-bottom: 3px;
        padding-left:25px;
    }

    &__view-search {
        background-image: url('../../images/search-blue.svg');
        background-repeat: no-repeat;
        background-position: 0px 4px;
        background-size: 20px 20px;
        padding-left:25px;
    }

    .spinner {
        visibility: hidden;
    }

    &--is-loading {
        .spinner {
            opacity: 1;
            top:50%;
            visibility: visible;
            z-index: 2;

            span {
                background-color: $dabas-blue;;
            }
        }
    }
}