@import '../../framework/helpers.scss';
@import '../../framework/common.scss';

.newsroom-hero {
    position: relative;

    &__content {
        @include fit-content();
    }

    &__background {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;

        .newsroom-hero--no-link & {
            padding-bottom: 115px;
        }
    }

    &__heading {
        font-size: 2.5rem;
        color: $white;
        text-align: center;
        line-height: 3.4rem;
        width: 931px;
        max-width: 90%;
        margin-top: 6rem;

        .newsroom-hero--no-bg & {
            color:$text-black;
        }

        @include breakpoint(xmedium) {
            font-size: 3.2rem;
            line-height: 4.6rem;
            margin-top: 9rem;
        }

       @include breakpoint(large) {
            font-size: 4rem;
            line-height: 5.4rem;
            margin-top: 11.5rem;
       } 
    }

    &__link {
        text-transform: uppercase;
        text-decoration: none;
        font-size: 1.3rem;
        font-weight: 600;
        letter-spacing: 1px;
        position: relative;
        padding: 0 1px 7px 1px;
        display: inline-block;
        margin-top: 2.5rem;
        color: $white;
        margin-bottom: 6rem;

        &:after {
            border-bottom: 2px solid $white;
            bottom: 0px;
            content: "";
            height: 0;
            left: 0;
            position: absolute;
            transition: bottom 0.1s ease-in-out, color 0.1s ease-in-out;
            width: 100%;
        }

        .newsroom-hero--no-bg & {
            color:$text-black;

            &:after {
                border-bottom: 2px solid $text-black;
            }
        }

        &:hover {
            &:after {
                bottom: 5px;
            }
        }

        @include breakpoint(large) {
            margin-bottom: 9.3rem;
        }
    }


    &__preamble {
        color:$white;
        font-size: 2rem;
        font-weight: 300;
        max-width: 90%;
        text-align: center;
        width: 931px;
        
        .newsroom-hero--no-bg & {
            color:$text-black;
        }
    }
}