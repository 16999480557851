/* COMPARE PRODUCTS PAGE */
@import '../../framework/helpers.scss';
@import '../../framework/styleguide.scss';
.swipe-indicator {
    display: flex;
    border: 1px solid #e4e4e4;
    border-radius: 3px;
    padding: 2rem;
    align-items: center;
    margin-bottom: 2.4rem;
  }
  
  .swipe-indicator img {
    margin-right: 1.7rem;
  }
  
  .compare-products {
    &__wrapper {
        @include fit-content();
    }

    &__wrapper-inner {
        @include content-padding();
        padding-top: 5.3rem;
    }

    &__articles-wrapper {
        overflow-x: scroll;
        overflow-y: hidden;
    }

    &__heading-loading {
        height: 24px;
        background: #cecece;
        width: 65%;
        top: 30px;
        overflow: hidden;
        position: absolute;
        transition: opacity 0.25s ease-in-out;
        opacity: 0;
        z-index: 1;

        .compare-products__product--is-loading & {
            opacity: 1;

            &:after {
                animation: placeholderShine 2.5s linear infinite;
                clip-path: margin-box;
                background-image: linear-gradient(90deg,rgba(206,206,206, 1),#e6e4e4,rgba(206,206,206, 1));
                content: '';
                display: block;
                height:26px;
                position: absolute;
                width:102%
            }
        }
    }

    &__product-image-loading {
        width: 120px;
        height: 120px;
        border-radius: 100%;
        border: 8px solid #ddd;
        margin-top: auto;
        position: absolute;
        top: 83px;
        overflow: hidden;

        transition: opacity 0.25s ease-in-out;
        opacity: 0;

        .compare-products__product--is-loading & {
            opacity: 1;
        }

        img {
            width: 66%;
            object-fit: initial;
            height: auto;
            position: absolute;
            top: 50%;
            transform: translateY(-50%) translateX(-50%);
            left: 50%;
        }
    }

    &__product-text {
        background: rgba($black, 0.2);
        height:26px;
        width: 15%;
    }
    &__row {
        display: flex;

        & > div:nth-child(even) {
            background-color: #fff;
        }

        &:nth-child(3n) {
            .compare-products__product-loading {
                width:25%;
            }
        }

        &--section-header {
            .compare-products__product,
            .compare-products__product-field  {
                border-bottom: 2px solid #ddd;
                padding-top: 4rem;
            }

            .compare-products__product-field {
                font-size: 1.3rem;
                color: #aaa;
                letter-spacing: 1px;
                text-transform: uppercase;
            }
        }

        &--thick-border {
            .compare-products__product,
            .compare-products__product-field {
              border-bottom: 2px solid #ddd;
            }
        }

        &--product-sheet {
            .compare-products__product,
            .compare-products__product-field {
              border-bottom: none;
              padding: 2rem;
            }
        }

        &--product-header {
            min-height:253px;
            .compare-products__product {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                padding: 3rem 2rem 4.9rem 2rem;
                position: relative;

                &:before {
                    display: none;
                }
            }

            h2 {
                font-size: 1.6rem;
                color: #222;
                margin-bottom: 2.9rem;
                line-height: 1.5;
                opacity: 1;
                position: relative;
                transition: opacity 0.25s ease-in-out;
                z-index: 2;

                .compare-products--is-loading {
                    opacity: 0;
                }

                a {
                    margin-left: 0.5rem;
                    position: relative;
                    top: -1px;
                  }
                  
            }
        }
    }

    &__product-field {
        width: 187px;
        border-bottom: 1px solid #ddd;
        padding: 2.2rem 2rem 2.2rem 0;
        font-weight: 600;
        position: relative;
    }

    &__product {
        width: 243px;
        padding: 2.2rem 1.5rem 2.2rem 2rem;
        border-bottom: 1px solid #ddd;
        overflow-wrap: break-word;
        position: relative;

        a {
            color:$darkish-blue;
            text-decoration: none;
        }

        .button {
            color:$white;
        }

        &-content {
            opacity: 1;
            transition: opacity 0.25s ease-in-out;
            position: relative;
            z-index: 2;

            .compare-products--is-loading & {
                opacity: 0;
            }
        }

        &-loading {
            background:rgba(206,206,206, 1);
            content: '';
            display: block;
            height:26px;
            overflow: hidden;
            opacity: 0;
            position: absolute;
            top:2.2rem;
            width:15%;
            transition: opacity 0.25s ease-in-out;
            z-index: 1;
            
            .compare-products--is-loading & {
                opacity: 1;
                &:after {
                    animation: placeholderShine 2.5s linear infinite;
                    clip-path: margin-box;
                    background-image: linear-gradient(90deg,rgba(206,206,206, 1),#e6e4e4,rgba(206,206,206, 1));
                    content: '';
                    display: block;
                    height:26px;
                    position: absolute;
                    width:102%
                }
            }
        } 

        a.button-large {
            width: 100%;
            text-decoration: none;
            font-weight: 600;
            display: block;
            box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
            border-radius: 4px;
            font-size: 1.5rem;
            text-align: center;
            line-height: 1.2;
        }
    }

    &__product-field,
    &__product {
        flex-shrink: 0;
        flex-grow: 1;
    }

    &__info {
        vertical-align: middle;
        margin-left: 0.2rem;
    }

    &__product-image {
        width: 120px;
        height: 120px;
        background: $white;
        border-radius: 100%;
        border: 8px solid #ddd;
        margin-top: auto;
        overflow: hidden;
        position: relative;
        img {
            max-width: 120px;
            max-height: 120px;
            object-fit: cover;
            
        }

        &--is-missing {
            img {
                width: 66%;
                object-fit: initial;
                height: auto;
                position: absolute;
                top: 50%;
                transform: translateY(-50%) translateX(-50%);
                left: 50%;
            }
        }
    }

    &__content-list {
        padding-left:20px;
    }
  }
  
  /* COMPARE ARTICLES MEDIA QUERIES */
  
  @media only screen and (min-width: 40em) {
    .swipe-indicator {
      display: none;
    }
  }

  .popup {
    position: relative;
    &:hover {
        .popup__content {
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
            animation-name: bounce;
            animation-duration: 550ms;
            animation-timing-function: cubic-bezier(0.17, 1.18, 1, 1.01);
            animation-fill-mode: forwards;
            transform: scale(1);

            @include breakpoint(xmedium) {
                animation: none;
                transform: translate3d(0,50%,0) rotate3d(0,0,0,0);
            }
        }
    }
    
    &__content {
        box-sizing: border-box;
        background: #0b3358;
        border-radius: 20px;
        color: #fff;
        display: block;
        filter: drop-shadow(0 0 30px rgba(0,0,0,0.2));
        font-weight: 300;
        opacity: 0;
        padding: 20px;
        position: absolute;
        transition: opacity 0.3s, transform 0.3s;
        visibility: hidden;
        z-index: 10;
        width: 100%;
        top: calc(100% + 8px);

        &:before {
            background-image: url("../../images/tooltip.svg");
            background-repeat: no-repeat;
            background-size: 100%;
            content: '';
            display: block;
            height: 35px;
            position: absolute;
            left: calc(50% - 43px);
            top: -20px;
            width: 35px;
            transform: rotate(-110deg) scaleX(-1);

            @include breakpoint(xmedium) {
                left: -30px;
                transform: none;
                top: calc(50% - 15px);
            }
        }

        p {
            color:$white;
            margin-bottom: 0;
        }
    
        @include breakpoint(xmedium) {
            bottom: 0;
            left: calc(100% + 40px);
            min-width: 320px;
            max-width: 400px;
            transform-origin: -50% 50%;
            transform: translate3d(0,50%,0) rotate3d(1,1,1,30deg);
            width: auto;
            top: unset;
        }
    }
    
}
