@import '../../framework/common.scss';
@import '../../framework/styleguide.scss';

.product-card {
    background: $white-two;
    box-shadow: 0 0 5px 0 rgba($black, 0.2);
    border-radius: 3px;
    width: 100%;
    font-size: 1.1rem;
    font-weight: 600;
    letter-spacing: 0.93px;
    margin: 0 1% 3.1rem 1%;
    text-align: center;
    position: relative;
    cursor: pointer;
    opacity: 1;
    scroll-snap-align: center;
    transition: all 0.3s ease-in-out;

    .product-cards--is-firstfetch & {
        background: $very-light-pink-two;
        box-shadow: none;

        &:hover {
            box-shadow: none;
        }
    }

    .product-cards--is-filtering & {
        opacity: 0.3;
    }

    &:hover {
        box-shadow: 0 0 15px 0 rgba($black, 0.2);
    }

    @include breakpoint(medium) {
        flex: 0 0 46.25%;
        margin: 0 1.875% 3.1rem 1.875%;
    }

    @include breakpoint(xxxmedium) {
        flex: 0 0 23.070175438%;
        margin: 0 0.964912281% 3.1rem 0.964912281%;
        font-size: 1.3rem;
    }

    &__new {
        position: absolute;
        top: -6px;
        right: -6px;
        height: 24px;
        width: 24px;
        background: #e44b14;
        border-radius: 50%;
        padding: 3px;
        color: $white;
        font-weight: 600;
        font-size: 11px;
        text-transform: uppercase;

        .product-cards__list--list-view & {
            left: -10px;
            right: auto;
            top: -10px;
            z-index: 3;
        }
    }
    
    h3 {
        color: $dabas-blue;
        font-size: 1.5rem;
        letter-spacing: 0;
        word-break: break-word;

        @include breakpoint(xxxmedium) {
            font-size: 1.7rem;
        }

        .product-cards__list--list-view & {
            font-weight: 400;
            width: 20%;

            &:hover {
                text-decoration: underline;
            }
        }

        &.product-card__subheading {
            font-weight: 300;
            color: #222;
            font-size: 1.3rem;
            margin: 12px 0 0;
            line-height: 1.2;

            &--no-margin {
                margin:0;
            }

            @include breakpoint(xxxmedium) {
                font-size: 1.3rem;
            }

            .product-cards__list--list-view & {
                display: none;
            }
        }
    }

    &__container {
        height: 100%;
        position: relative;
        transition: all 0.3s ease-in-out;
        .product-cards__list--list-view & {
            display: flex;
            align-items: center;
            padding: 0;
            width: 100%;
            justify-content: flex-start;
        }

        .product-cards--is-firstfetch & {
            opacity: 0;
        }    
    }

    &__product-link {
        display: flex;
        flex-direction: column;
        color: $black;
        height: 100%;
        text-decoration: none;
        padding: 1.5rem 2.5rem 0 2.5rem;

        @include breakpoint(xxxmedium) {
            padding-top: 3rem;
        }

        .product-cards__list--list-view & {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0;
            width: 100%;
            justify-content: flex-start;
        }
    }

    &__content-bottom {
        margin-top:auto
    }

    &__manufacturer {
        font-size: 1.5rem;
        letter-spacing: 0;
        display: block;
        font-weight: 300;
        line-height: 1.2;
        margin-bottom: 18px;

        @include breakpoint(xxxmedium) {
            font-size: 1.7rem;
        }

        .product-cards__list--list-view & {
            display: block;
            font-weight: 400;
            margin-bottom: 0;
            width: 16%;
        }
    }

    &__name {
        display: none;
        font-size: 1.5rem;
        letter-spacing: 0;
        font-weight: 300;
        line-height: 1.2;
        margin-bottom: 0;

        @include breakpoint(xxxmedium) {
            font-size: 1.7rem;
        }

        .product-cards__list--list-view & {
            display: block;
            font-weight: 400;
            margin-bottom: 0;
            width: 25%;
        }
    }

    &__checkbox {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        .product-cards__list--list-view & {
            width: 55px;
            height: auto;
            border-radius: 0;
            position: relative;
            top: auto;
            left: auto;

            input[type="checkbox"] {
                & + label {
                    width: 100%;
                    height: 100%;
                    background: #e7e7e6;
                    cursor: pointer;
                }
            }
        }
    }

    &__product-image {
        border: 8px solid $very-light-pink-two;
        display: inline-block;
        width: 52.631578947%;
        overflow: hidden;
        border-radius: 50%;
        padding-top: calc(52.631578947% - 16px);
        position: relative;

        img {
            /*
            height: 80%;
            left: 50%;
            position: absolute;
            top: 10%;
            transform: translateX(-50%);
            */
            height:100%;
            max-height: 100%;
            left: 50%;
            position: absolute;
            top: 0;
            transform: translateX(-50%);
            object-fit: cover;
            max-width: 100%;
        }

        &--is-missing {
            img {
                height: 70%;
                width: 60%;
                top: 50%;
                transform: translateY(-50%) translateX(-50%);
                left: 50%;
            }
        }

        .product-cards__list--list-view & {
            width: 43px;
            height: 43px;
            order: -1;
            flex: 0 0 43px;
            border-color: #ddd;
            margin: 1.4rem 1.7rem;
            border-width: 4px;
            padding-top:0;
        }
    }

    &__info {
        margin: 2.6rem 0 0 0;
        width: 100%;
        border-top: 1px solid $very-light-pink;

        & > div {
            padding: 0.9rem 0;
            border-bottom: 1px solid $very-light-pink;
            display: flex; 

            &:first-child {
                display: none;
            }

            .product-cards__list--list-view & {
                padding: 0;
                border-bottom: none;

                &:first-child {
                    display: block;
                    width: 35%;
                }

                &:last-child {
                    margin-left: auto;
                }
            }
        }

        .product-cards__list--list-view & {
            margin: 0;
            width: 39.539170508%;
            border-top: none;
            font-size: 1.5rem;
            display: flex;

            @include breakpoint(xxxmedium) {
                font-size: 1.7rem;
            }
        }
    }

    &__gtin-header,
    &__art-nr-header {
      text-align: left;
      margin-right: auto;

      .product-cards__list--list-view & {
        display: none;
      }
    }

    &__gtin,
    &__art-nr {
      text-align: right;
      margin-left: auto;
    }

    &__art-nr {
        .product-cards__list--list-view & {
            margin-left: 0;
        }
    }
  
    &__gtin,
    &__art-nr,
    &__size {
        font-weight: 400;
    }

    &__manufacturer-logo {
        line-height: 1.2;
        display: block;
        padding: 2rem 0;
        
        img {
            max-height: 40px;
            max-width: 75%;
        }

        .product-cards__list--list-view & {
            display: none;
        }
    }

    &__placeholder {
        opacity: 0;
        bottom:0;
        padding: 1.5rem 2.5rem 0 2.5rem;
        position: absolute;
        top:0;
        transition: all 0.3s ease-in-out;
        width:100%;

        overflow: hidden;
       

        .product-cards--is-firstfetch & {
            opacity: 1;

            &:before {
                animation: placeholderShine 2.5s linear infinite;
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                width: 102%;
                background-image: linear-gradient(90deg,$very-light-pink-two,#f8f8f8,$very-light-pink-two);
                overflow: hidden;
            }
        }

        @include breakpoint(xxxmedium) {
            padding-top: 3rem;
        }

        &-heading {
            background-color: $very-light-pink;
            margin:0 auto; 
            max-width:150px;
            height:20px;
            overflow: hidden;
            position: relative;
            top:5px;
        }

        &-subheading {
            background-color: rgba($black, 0.1);
            margin:0 auto; 
            max-width:50px;
            height:16px;
            position: relative;
            top:8px;
        }

        &-image {
            background-color: rgba($black, 0.1);
            //border: 8px solid rgba($black, 0.2);
            display: inline-block;
            width: 52.631578947%;
            overflow: hidden;
            border-radius: 50%;
            padding-top: 52.631578947%;
            position: relative;
            top:29px;
        }

        &-text,
        &-text-2 {
            background-color: rgba($black, 0.1);
            height: 16px;
            position: relative;
            top: 67px;
            width: 40px;
        }

        &-text-2 {
            top: 91px;
            width: 100px;
        }

        &-heading,
        &-subheading,
        &-image,
        &-text,
        &-text-2 {
            display: none;
            /*overflow: hidden;
            &:before {
                animation: placeholderShine 2.5s linear infinite;
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                width: 100%;
                background-image: linear-gradient(90deg,$very-light-pink,#e8e8e8,$very-light-pink);
            }*/
        }
    }
}


@keyframes placeholderShine{
    0%{
        transform:translateX(-100%);
    }
    to{
        transform: translateX(200%);
    }
}