.image {
    position: relative;

    &__wrapper {
        position: relative;
    }

    &__image {
        display: block;
        position: relative;
        z-index: 2;
    }

    &__shadow {
        background-image: url('../../images/stripesbg.png');
        background-repeat: repeat;
        background-size: 75%;
        position: absolute;
        top: 20px;
        left: -20px;
        right: 20px;
        bottom: -20px;      
        transform: translateZ(1px) scale(0.95);
        /*transform-origin: 264%;*/
        z-index: 1;

        .marketers-info--img-right & {
            /*transform-origin: 150%;*/
            bottom:-20px;
            left: 20px;
            right: -20px;
            top:20px;
        }

        .marketers-info--img-dotted & {
            background-image: url('../../images/dottedbg.png');
        }

        .marketers-intro & {
            transform: translateZ(1px) scale(0.8);
        }

    }
    
    
    
    
    /*position: relative;
    transition: transform 0.5s;
    /*transform-style: preserve-3d;*/
    /*height:500px;
    width:500px;
    z-index: 2;

    &__front {
        bottom:0;
        left:0;
        position: absolute;
        right: 0;
        top:0;
        transform: translateZ(0);
        z-index: 4;
    }

    &__shadow {
        bottom:0;
        left:0;
        position: absolute;
        right: 0;
        top:0;
        background: green;
        transform: translateZ(-1px);
        z-index: 2;
    }

    &__shadow-inner {
        text-align: center;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }*/
}