@import '../../framework/styleguide.scss';
@import '../../framework/helpers.scss';

.recipes {
    display: flex;
    flex-wrap: wrap;
    margin-left:-16px;
    margin-right: -16px;

    &__recipe {
        border-radius: 3px;
        padding:16px;
        text-align: left;
        text-decoration: none;
        transition: all 0.3s ease;
        width:100%;

        @include breakpoint(medium) {
            width:50%;
        }

        @include breakpoint(large) {
            width:25%;
        }

        &:hover {

            .recipes__recipe-image {
                transition: all 3s cubic-bezier(0.03, 0.15, 0, 0.92);
                transform: scale(1.15);
            }
        }

        &:nth-child(n+5) {
            display: none;

            .recipes--show-all & {
                display: block;
            }
        }
    }

    &__recipe-image-container {
        background-color: rgba(0,0,0, 0.05);
        background-image: url('../../images/coffee.svg');
        background-repeat: no-repeat;
        background-size: 24px;
        background-position: center;
        margin-bottom:10px;
        overflow: hidden;
        padding-top:66.6666666666667%;
        position: relative;
        width:100%;
    }

    &__recipe-image {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border-radius: 3px;
        bottom:0;
        height: 100%;
        left:0;
        position: absolute;
        right:0;
        top:0;
        transition: all .8s cubic-bezier(0.03, 0.15, 0, 0.92);
        width:100%;
        
    }

    &__recipe-heading {
        color:$text-black;
        font-weight: 600;
        font-size: 1.6rem;
    }

    &__recipe-origin {
        color:$text-black;
        font-size:1.4rem;
        font-weight: 300;
    }

    &__toggle {
        background:$white;
        border:solid 1px $very-light-pink;
        border-radius: 3px;
        cursor: pointer;
        font-size: 1.5rem;
        font-weight: normal;
        margin:30px auto 0;
        padding: 12px 30px;
        transition: all 0.3s ease;

        &:hover,
        &:focus {
            box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.2);
        }
    }

    &__preamble {
        font-size: 1.6rem;
        font-weight: 300;
        display: inline-block;
        margin: 8px 0 20px;

        @include breakpoint(large) {
            font-size: 1.8rem;
        }
    }

    &__container {
        opacity: 1;
        transition: all 0.3s ease;
        width:100%;

        .product-sheet-box--recipes-is-loading & {
            opacity: 0;
        }
    }

    &__loading {
        .spinner {
            top: calc(50% - 13px);
            span {
                background-color:$black;
            }
        } 

        .product-sheet-box--recipes-is-loading & {            
            min-height: 30px;
            width:100%;
        
            .spinner {
                opacity: 1;
            }
        }
    }
}
