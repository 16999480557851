@import '../../framework/styleguide.scss';
.watch-overlay {
    &__label {
        color: $white;
        font-weight: 400;
    }

    &__radio {
        cursor: pointer;
        width: 343px;
        max-width: 90%;
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
    }

    &__input {
        margin-top: 1.2rem;
        margin-bottom: 2.2rem;
    }

    &__subheading {
        color: $white;
        margin-bottom: 1.3rem;
    }

    &__submit {
        margin: 2.4rem 0 3.6rem 0;
        text-align: center;
        width: 100%;
    }

    &__cancel {
        background: transparent;
        border: none;
        color: rgba($white, 0.4);
        cursor: pointer;
        font-size: 1.3rem;
        font-weight: 400;
        padding: 0;
    }

    &__step {
        opacity: 0;
        transition: opacity 0.5s ease;
        visibility: hidden;

        &--is-visible {
            opacity: 1;
            visibility: visible;
        }
    }
}