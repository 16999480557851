@import '../../framework/styleguide.scss';
@import '../../framework/helpers.scss';
@import '../../framework/common.scss';
.business-list {
    background-color:$off-white;
    padding-bottom: 86px;

    &__wrapper {
        @include fit-content();
    }

    &__content {
        @include content-padding();
        padding-bottom: 0;

        @include breakpoint(large) {
            padding-bottom: 0;
        }
    }
}


.businesses {
    border-top: 1px solid #e4e4e4;
    font-weight: 400;
    margin-bottom: 4.2rem;
  }
  
  .businesses .branch-news-item {
    display: flex;
  }
  
  .branch-news-item {
    padding: 1.8rem 0;
    border-bottom: 1px solid #e4e4e4;
  }
  
  .business-link {
    display: flex;
    flex-direction: column;
    letter-spacing: 0;
    width: 50%;
  }
  
  .business-link a {
    font-weight:bold;
    color:$dabas-blue;
    width: 100%;
    margin-right: 0;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
  }
  
  .business-articles-link a {
    color:$dabas-blue;
    font-weight: bold;
    margin-right: 0;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
  }
  
  .business-link a:after,
  .branch-news-item a.no-arrow:after {
    content: none;
  }
  
  .branch-news-item a:after {
    content: "";
    display: inline-block;
    margin-left: 0.6rem;
    width: 7px;
    height: 11px;
    background-size: 7px 11px;
    background-image: url("../../images/arrow-right.svg");
    background-repeat: no-repeat;
  }
  
  .branch-news-item .business-articles-link {
    margin-left: auto;
  }
  
  
  /* BRANCH NEWS MEDIA QUERIES */
  @media only screen and (min-width: 52em) {
    .branch-news-item .business-link {
      flex-direction: row;
    }
    
    .branch-news-item a {
      margin-right: 1.8rem;
    }

    .branch-news-item .business-link a {
      width: 54%;
    }
  }