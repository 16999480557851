@import '../../framework/helpers.scss';
@import '../../framework/common.scss';
/* CATEGORY ICONS */
.category-icon {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 66% 66%;
    border-radius: 50%;
    border: 8px solid #ddd;
    width: 106px;
    height: 106px;
    display: inline-block;
  }
  
  .category-icon.small {
    width: 85px;
    height: 85px;
    border: 4px solid #ddd;
  }
  
  .category-icon.frozen-food {
    background-color: #00529f;
    background-image: url("../../images/category-icon-djupfryst.svg");
  }
  
  .category-icon.fresh-food {
    background-color: #3b8541;
    background-image: url("../../images/category-icon-farskvaror-kylvaror.svg");
  }
  
  .category-icon.colonial-food {
    background-color: #eba012;
    background-image: url("../../images/category-icon-kolonial-speceri.svg");
  }
  
  .category-icon.non-food {
    background-color: #7c1213;
    background-image: url("../../images/category-icon-non-food.svg");
  }
  
  /* SLIDER */
  
  .slider.is-animating {
    -webkit-transition: -webkit-transform 200ms cubic-bezier(0.5, 0, 0.5, 1);
    -moz-transition: -moz-transform 200ms cubic-bezier(0.5, 0, 0.5, 1);
    -ms-transition: -ms-transform 200ms cubic-bezier(0.5, 0, 0.5, 1);
    -o-transition: -o-transform 200ms cubic-bezier(0.5, 0, 0.5, 1);
    transition: transform 200ms cubic-bezier(0.5, 0, 0.5, 1);
  }
  
/* EXPLORE PRODUCTS */
.explore-products  {
    padding-bottom:68px;

    @include breakpoint(large) {
        padding-bottom: 136px;
    }

    &__fit-content {
      @include fit-content();
    }

    &__content-padding {
      @include content-padding();
  
      padding-top: 2.7rem;

      .explore-products--no-header & {
        padding-top: 0; 
      }
    }
}
  
  .explore-products.sub-page .content-padding,
  .explore-products.sub-page .content-padding-no-bottom {
    padding-top: 0rem;
  }
  
  .explore-products.sub-page .explore-products__content-padding {
    padding-bottom: 0;
  }
  
  .explore-products .title-with-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3.6rem;
    text-align: center;
  }
  
  .explore-products .title-with-icon .product-count {
    margin-bottom: 0;
  }
  
  .explore-products .title-with-icon i {
    margin-right: 2.5rem;
    flex-shrink: 0;
  }
  
  .explore-products h1 {
    margin-bottom: 0;
  }
  
  .explore-products .product-count {
    font-size: 2rem;
    color: #999;
    margin-bottom: 3.6rem;
    display: block;
  }
  
  .explore-products .options {
    border-top: 1px solid #e4e4e4;

    &--no-header {
      border-top:none;
    }
  }
  
  .explore-products a.option {
    padding: 1.3rem 3.1rem 1.3rem 0;
    border-bottom: 1px solid #e4e4e4;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-weight: 600;
    position: relative;
  }
  
  .explore-products a.option:after {
    content: "";
    width: 7px;
    height: 11px;
    background-size: 7px 11px;
    background-image: url("../../images/arrow-right.svg");
    background-repeat: no-repeat;
    position: absolute;
    top: calc(50% - 6px);
    right: 0;
  }
  
  .explore-products a.option .category-wrapper {
    display: flex;
    flex-direction: column;
  }
  
  .explore-products a.option:hover .category {
    text-decoration: underline;
  }
  
  .explore-products a.option .category {
    display: flex;
    align-items: center;
    color: #0b3358;
  }
  
  .explore-products a.option i {
    margin-right: 1.5rem;
    flex-shrink: 0;
  }
  
  .explore-products a.option .category-product-count {
    margin-top: 0.6rem;
    color: #fff;
    border-radius: 3px;
    padding: 3px 7px;
    align-self: flex-start;
  }
  
  .explore-products a.option .category-product-count.frozen-food {
    background-color: #00529f;
  }
  
  .explore-products a.option .category-product-count.fresh-food {
    background-color: #3b8541;
  }
  
  .explore-products a.option .category-product-count.colonial-food {
    background-color: #eba012;
  }
  
  .explore-products a.option .category-product-count.non-food {
    background-color: #7c1213;
  }
  
  /* EXPLORE PRODUCTS MEDIA QUERIES */
  
  @media only screen and (min-width: 40em) {
    .explore-products .content-padding {
      padding-top: 8.6rem;
    }
  
  .explore-products.sub-page .content-padding,
  .explore-products.sub-page .content-padding-no-bottom {
    padding-top: 4.1rem;
  }
  
    .explore-products .product-count, 
    .explore-products .title-with-icon {
        margin-bottom: 6.7rem;
    }

    .explore-products.sub-page .product-count {
        margin-bottom:4rem;
    }
  
    .explore-products .title-with-icon {
      flex-direction: row;
      text-align: left;
    }
  
    .explore-products a.option .category-wrapper {
      flex-direction: row;
      justify-content: space-between;
      flex: auto;
    }
  
    .explore-products a.option .category {
      margin-right: 4.5rem;
    }
  
    .explore-products .option .category-product-count {
      margin-left: auto;
      margin-top: 0;
    }
  }