@import '../../framework/styleguide.scss';
@import '../../framework/common.scss';
.spinner {
    display: block;
    margin: 0;
    width: 70px;
    opacity: 0;
    position: absolute;
    top: 17px;
    left: 50%;
    transform: translateX(-50%);

    .search-page-filter-bar--is-loading &,
    .newsroom-products--is-loading & {
        opacity: 1;
    }

    .newsroom-products & {
        top:157px;

        @include breakpoint(large) {
            top:187px;
        }
    }

    span {
        background-color: $white;
        width: 18px;
        height: 18px;
        border-radius: 100%;
        display: inline-block;
        animation: sk-bouncedelay 1.4s ease-in-out infinite both;

        .newsroom-products--is-loading & {
            background-color: $text-black;
        }
    }

    .bounce1 {
        animation-delay: -.32s;
    }

    .bounce2 {
        animation-delay: -.16s;
    }
}

@keyframes sk-bouncedelay{
    0%, 80%, 100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1);
    }
}
