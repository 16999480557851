@import '../../framework/styleguide.scss';
@import '../../framework/common.scss';

.news-list {
    &__list {
        border-top: 0.5px solid rgba($brown-grey, 0.2);
        font-weight: 300;
        letter-spacing: 0.08rem;
        margin-bottom: 4.2rem;
    }
    
    &__item {
        padding: 1.8rem 0;
        border-bottom: 0.5px solid rgba($brown-grey, 0.2);

        @include breakpoint(xmedium) {
            align-items: center;
            display: flex;
        }
    }

    &__item-date {
        margin-bottom: 0.2rem;

        @include breakpoint(xmedium) {
            margin-right: 1.8rem;
            margin-bottom: 0;
        }
    }

    a {
        font-weight: 600;
        color: $dabas-blue;
        text-decoration: none;
        letter-spacing: 0;

        &:hover {
            text-decoration: underline;
        }
        
        &:after {
            content: "";
            display: inline-block;
            margin-left: 0.6rem;
            width: 7px;
            height: 11px;
            background-size: 7px 11px;
            background-image: url("../../images/arrow-right.svg");
            background-repeat: no-repeat;
        }

        @include breakpoint(xmedium) {
            margin-right: 1.8rem;
        }
    }


    &__item-logo {
        border-radius: 3px;
        background-color: $white;
        box-shadow: 0 0 3px 0 rgba($black, 0.3);
        width: 69px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1.6rem;

        a {
            display: block;
            height: 100%;
            margin:0;
            position: relative;
            width:100%;

            &:after {
                content: none;
                display: none;
            }
        }

        @include breakpoint(xmedium) {
            width: 133px;
            height: 46px;
            margin-left: auto;
            margin-top: 0;
        }

        img {
            max-width: 78%;
            height: auto;
            max-height: 86%;
            position: absolute;
            top:50%;
            left:50%;
            transform: translateX(-50%) translateY(-50%);
        }
    }
}
