@import '../../framework/styleguide.scss';
@import '../../framework/common.scss';
@import '../../framework/helpers.scss';

.newsroom-usp {
    background-color: $off-white;
    text-align: center;

    @include breakpoint(xmedium) {
        text-align: left;
    } 

    &__container {
        @include fit-content();
    }

    &__container-inner {
        @include content-padding();
    }

    &__item {
        margin-bottom: 8rem;

        @include breakpoint(xmedium) {
            display: flex;
            margin-bottom: 4.8rem;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    &__text,
    &__image {
        width: 46.5rem;
        max-width: 100%;
    }

    &__image {
        @include breakpoint(xmedium) {
            width: 24.2rem;
            margin: 0 7.3rem 0 0;

            img {
                width: 100%;
                height: auto;
                margin: 0;
            }
        }
    }

    &__text {
        h2 {
            margin-bottom: 1.2rem;

            @include breakpoint(xmedium) {
                margin-top: 2.5rem;
                margin-bottom: 1.8rem;
            }
        }

        a {
            color: $dabas-blue;
        }

        img {
            width: 18rem;
            max-width: 100%;
            height: auto;
            margin-bottom: 1.2rem;
        }
    }
}