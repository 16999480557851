@import '../../framework/styleguide.scss';

.single-business {
    background-color: $dabas-blue;
    height: auto;
    display: flex;
    align-items: center;
    height: 53px;
    position: fixed;
    padding: 0;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;

    a {
        color:$white;
        display: flex;
        font-size: 1.3rem;
        font-weight: 600;
        text-decoration: none;

        &:before {
            content: "";
            align-self: center;
            margin-right: 1.6rem;
            width: 6px;
            height: 11px;
            background-size: 6px 11px;
            background-image: url('../../images/arrow-left-white.svg');
            background-repeat: no-repeat;
        }
          
    }

    &__content {
        padding: 0 2.638888888%;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
    }
}