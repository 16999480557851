@import '../../framework/helpers.scss';
@import '../../framework/styleguide.scss';
.video {
    &__content {
        @include fit-content();
    }

    &__content-inner {
        @include content-padding();
    }

    &__container {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;
        max-width: 100%;

        iframe {
            bottom: 0;
            left: 0;
            height: 100%;
            position: absolute;
            right: 0;
            top:0;
            width: 100%;
        }
    }

    &__link {
        text-transform: uppercase;
        text-decoration: none;
        font-size: 1.3rem;
        font-weight: 600;
        color: $dabas-blue;
        display:inline-block;
        letter-spacing: 1px;
        margin-top: 4.2rem;
        position: relative;
        padding: 0 1px 7px 1px;

        &:hover {
            color: $reddish-orange;
            &:after {
                bottom:5px;
            }
        }
        
        &:after {
            border-bottom: 2px solid $reddish-orange;
            content: "";
            position: absolute;
            width: 100%;
            height: 0;
            left: 0;
            bottom: 0px;
            transition: bottom 0.1s ease-in-out, color 0.1s ease-in-out;
        }
    }
}