@import '../../framework/common.scss';
@import '../../framework/styleguide.scss';
.drop-down-menu {
    margin-left: auto;
    position: relative;

    &--is-open {
        .drop-down-menu__menu {
            display: block;
        }
    }

    &__button {
        background-color: rgba($very-light-pink, 0.26);
        border: none;
        border-radius: 3px;
        color:$white;
        cursor: pointer;
        font-size: 1.5rem;
        font-weight: 600;
        height:50px;
        padding:15px;
        position: relative;
        width:50px;

        @include breakpoint(large) {
            height:auto;
            padding:15px 42px;
            width:auto;
        }

        &:before {
            background-image: url('../../images/smily.svg');
            background-repeat: no-repeat;
            background-size: 100%;
            content:'';
            display: block;
            height:20px;
            left:15px;
            position: absolute;
            top: 15px; 
            width:20px;
        }

        &:after {
            background-image: url('../../images/arrow-white.svg');
            background-repeat: no-repeat;
            background-size: 100%;
            content:'';
            display: none;
            height:6px;
            position: absolute;
            right:20px;
            top:calc(50% - 2px);
            transition: all 0.6s ease;
            width:9px;

            @include breakpoint(large) {
                display: block;
            }
        }

        .drop-down-menu--is-open & {
            background-color: $white;
            color:$text-black;

            &:before {
                filter: invert(0%) sepia(84%) saturate(100%) hue-rotate(337deg) brightness(0) contrast(114%);
            }

            &:after {
                filter: invert(0%) sepia(84%) saturate(100%) hue-rotate(337deg) brightness(0) contrast(114%);
                transform: rotate(180deg);
            }
        }

        &-text {
            display: none;

            @include breakpoint(large) {
                display:inline-block;
            }
        }

        &-notification {
            background-color:$reddish-orange;
            border-radius: 50%;
            display: inline-block;
            height:16px;
            position: absolute;
            right:-8px;
            top:-8px;
            width: 16px;
        }
    }

    &__menu {
        background-color: $white;
        box-shadow: 0 3px 5px 0 rgba($black, 0.2);
        border-radius: 3px;
        border-top-right-radius: 0;
        display: none;
        font-size: 1.5rem;
        list-style: none;
        padding:0;
        position: absolute;
        right: 0;
        top: calc(100% - 3px);
        width: 270px;

        &-list {
            list-style: none;
            padding: 8px 20px 0;

            &--bottom {
                background:$off-white;
                border-bottom:solid 1px $very-light-pink-four;
                border-top:solid 3px rgba($black, 0.09);
                padding: 0 20px;
            }

            &--submenu {
                background:$off-white;
                font-size: 1.3rem;
                padding:28px 20px;

                .drop-down-menu__menu-list-item {
                    border-top:none;
                    a {
                        display: inline;
                        padding:0;
                    }
                }
            }
        }

        &-list-item {
            border-top:solid 1px rgba($black, 0.2);

            &:first-child {
                border-top:none;
            }

            a {
                color:$dabas-blue;
                display: flex;
                text-decoration: none;
                padding:20px 0;

                &:hover {
                    text-decoration: underline;
                }
            }

            &-notifications {
                display: inline-block;
                margin-left: auto;
            }

        }

        &-list-item-notification {
            background-color: $reddish-orange;
            border-radius: 50%;
            color:$white;
            display: inline-block;
            font-size: 1.3rem;
            font-weight: 600;
            height:22px;
            margin-left: 0;
            margin-right:8px;
            text-align: center;
            width:22px;
        }
    }

    &__menu-list-item {
        &--logout {
            a {
                background-image:url('../../images/sign-out.svg');
                background-repeat: no-repeat;
                background-position: center left;
                background-size: 16px;
            }
            
            .drop-down-menu__menu-list--submenu & {
                a {
                    padding-left:21px;
                }
            }
        }
    }
}