@import "../../framework/common.scss";
@import "../../framework/styleguide.scss";

.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  left: -100%;
  top: 0;
  background-color: $dabas-blue;
  display: flex;
  align-items: center;
  padding-top: 75px;
  flex-direction: column;
  opacity: 0;
  overflow: auto;
  -webkit-transition: opacity 0.5s ease;
  -moz-transition: opacity 0.5s ease;
  -ms-transition: opacity 0.5s ease;
  -o-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;

  &--open {
    left: 0;
    opacity: 1;
    -webkit-transition: opacity 0.5s ease;
    -moz-transition: opacity 0.5s ease;
    -ms-transition: opacity 0.5s ease;
    -o-transition: opacity 0.5s ease;
    transition: opacity 0.5s ease;
  }

  &__close {
    border: none;
    background: transparent;
    padding: 25px;
    position: absolute;
    top: 0px;
    right: 0;
    cursor: pointer;  

    @include breakpoint(medium) {
      padding: 39px;
    }
  }

  &__content {
    width: 343px;
    max-width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    label {
        color:$white;
        font-weight: 400;
    }
  }

  &__heading {
    font-weight: 400;
    font-size: 2.2rem;
    color: rgba($white, 0.5);
    letter-spacing: 0.293rem;
    text-transform: uppercase;
    padding-bottom: 2.6rem;
    margin-bottom: 2.4rem;
    border-bottom: solid 1px rgba($white, 0.2);
    display: block;
    width: 100%;
    text-align: center;
    line-height: 1.1;
    transition: opacity 0.25s ease;
    opacity: 1;

    @include breakpoint(medium) {
      padding-bottom: 3.6rem;
      margin-bottom: 3.4rem;
    }
  }

  .spinner {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
  }

  &--is-loading {
    .overlay__heading {
      opacity: 0;
    }

    .spinner {
      opacity: 1;
    }

  }

  
  
  @include breakpoint(medium) {
    padding-top: 150px;
  }
}