@import '../../framework/styleguide.scss';
@import '../../framework/common.scss';
@import '../../framework/helpers.scss';
/* GET STARTED SIGNUP */

.signup {
    background: $off-white;
    padding-bottom:100px;

    fieldset {
        max-width: 100%;
        min-inline-size: auto;
        width: 100%;
    }

    &__form-body {
        transition: opacity 0.3s ease;
        opacity: 1;
        
        max-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include breakpoint(medium) {
            align-items: flex-start;
        }

        @include breakpoint(xxmedium) {
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 72.5rem;
        }

    }

    &--is-loading {
        .signup__form-body {
            opacity: 0.5;
        }
    }

    &__wrapper {
        @include fit-content();
    }
    
    &__wrapper-inner {
        @include content-padding();
    }

    &__form-item {
        width: 100%;
        max-width: 100%;

        @include breakpoint(xxmedium) {
            width: 343px;
        }

        &--wide {
            flex-wrap: nowrap;
            width: 100%;
        }

        label {
            margin-bottom: 1.2rem;
            display: block;
        }

        input {
            width: 100%;
            margin-bottom: 1.6rem;
            box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
            

            @include breakpoint(xxmedium) {
                margin-bottom: 2.3rem;
            }           
        }

        textarea {
            font-size:1.6rem;
            height:150px;
            max-width:100%;
            width: 100%;
            margin-bottom: 1.6rem;
            box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
            padding:15px;
            
            @include breakpoint(xxmedium) {
                margin-bottom: 2.3rem;
            }           
        }
    }

    &__form-submit {
        width: 343px;
        max-width: 100%;

        @include breakpoint(xxmedium) {
            margin-top: 0.8rem;
        }

        button {
            box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
            width: 100%;
            margin: 1.6rem auto 0 auto;

            @include breakpoint(xxmedium) {
                width: 16.4rem;
            }
          }
    }

    &__form-terms {
        width: 100%;
        display: flex;
        justify-content: center;

        @include breakpoint(medium) {
            width: 100%;
            display: flex;
            justify-content: flex-start;
        }

        a {
            color: $darkish-blue;
            text-decoration: none;
        }
    }

    &__form-terms-inner {
        width: 343px;
        max-width: 100%;

        @include breakpoint(xxmedium) {
            width: 100%;
        }
    }

    &__options {
        border-top: solid 1px $very-light-pink-four;
    }

    &__option {
        border-bottom: solid 1px $very-light-pink-four;
    }

    &__option-toggle {
        background-color:transparent;
        border:none;
        color:$text-black;
        cursor:pointer;
        display:block;
        padding:20px 0 20px 50px;
        font-size: 2.2rem;
        font-weight:300;
        letter-spacing: 0;
        line-height: 3.2rem;
        text-align:left;
        position:relative;
        width:100%;

        &:before {
            background:url('../../images/arrow-down-big.svg');
            background-repeat: no-repeat;
            background-position: center center;
            content:'';
            height: 40px;
            left:0;
            position: absolute;
            width: 40px;
            transition: all 0.25s ease;
            transform: rotate(-90deg);

            .signup__option--is-open & {
                transform: rotate(0deg);
            }

            @media print {
                display: none;
            }
        }
    }

    &__option-content {
        display:none;
        padding:20px 0 100px 0;

        @include breakpoint(large) {
            padding:20px 0 100px 50px;
        }
        

        .signup__option--is-open & {
            display:block;
        }
    }

    &__option-text {
        color:$brown-grey-two;
        font-size: 2rem;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 3.2rem;
        margin-bottom:30px;
        max-width:530px;
    }

    &__mandatory {
        font-weight: 300;
        margin-bottom: 20px;
    }
}