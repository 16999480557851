@import "../../framework/common.scss";
@import "../../framework/styleguide.scss";
.hero {
    position: relative;

    @include breakpoint(large) {
        padding-bottom: 27.708333333%;
        height: 0;
    }

    &__bg-wrapper {
        align-items: center;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
        position: absolute;
        top:0;
        width: 100%;

        @media all and (-ms-high-contrast:none)
        {        
          left:0;
        }
    }

    &__background {
        background-position:center;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        height:100%;
        width:100%;

        .is-start & {
          opacity: 1;
        }
    } 

    &--is-parallax {
      .hero__background {
        transform: translateZ(-2px) scale(1.1);
        height: 140%;
        left:0;;
        top:-20%;
        width: 100%;
      }
    }

    &__heading {
        color: #fff;
        display: inline-block;
        font-size: 3.5rem;
        line-height: 1.1;
        text-align: center;
        margin: 62px auto;
        max-width: 90%;
        text-shadow: 0 0 40px rgba(0, 0, 0, 0.5);
        font-weight: 600;
        
        .hero--big-subheading & {
          margin-bottom:15px;
        }

        @include breakpoint(xmedium) {
            font-size: 5rem;
        }

        @include breakpoint (large) {
            font-size: 6rem;    
            margin: 0 auto 35px auto;

            .hero--big-subheading & {
              margin-bottom:15px;
            }
        }

        textarea {
          background: transparent;
          text-align: center;
        }
    }

    &--xbig-heading {
      .hero__heading {
        font-weight: 600;
        font-size: 5rem;
        color:$white;
        line-height: 5rem;
        margin-bottom: 1.7rem;

        @include breakpoint(xmedium) {
          font-size: 7.5rem;
          line-height: 7.5rem;
        }

        @include breakpoint(large) {
          font-size: 9rem;
          line-height: 9rem;
          margin-bottom: 1.5rem;
        }
      }
    }

    &__content-wrapper {
        bottom:0;
        left:0;
        position: relative;
        right:0;
        top:0;

        @include breakpoint (large) {
            position: absolute;
        }
    }

    &__content {
        align-items: center;
        display: flex;
        flex-direction: column;
        height:100%;
        justify-content: center;    
        width: 100%;
        margin: 0 auto;
        text-align: center;
        max-width: 1440px;

        form {
          width:100%;
        }
    }

    &__byline {
      color:$white;
      display: block;
      font-weight: 600;
      font-size: 2rem;
      margin-bottom: 1.7rem;
      margin-top: 13.2rem;
      letter-spacing: 0;
      padding: 0 40px;
      text-align: center;
      text-shadow: 0 0 40px rgba($black, 0.96);

      @include breakpoint(xmedium) {
        margin-top: 15.2rem;
        padding: 0;
      }

      @include breakpoint(large) {
        margin-top: 14.2rem;
        margin-bottom: 1rem;
      }

      textarea {
        background: transparent;
        text-align: center;
      }
    }

    &__subheading {
      color:$white;
      display: block;
      font-weight: 300;
      font-size: 2rem;
      margin-bottom: 7.8rem;
      padding: 0 40px;

      .hero--big-subheading & {
        font-size: 2rem;
        margin-bottom: 4rem;

        @include breakpoint(large) {
          font-size: 3.5rem;
        }
      }
  

      textarea {
        background: transparent;
        text-align: center;
      }
    }
}




  h1.hero-single-business-text {
    font-size: 2.5rem;  
    color: #fff;
    text-align: center;
    line-height: 3.4rem;
    width: 931px;
    max-width: 90%;
    margin-top: 6rem;
  }
  
  .hero .read-more-link {
    margin-top: 2.5rem;
    color: #fff;
    margin-bottom: 6rem;
  }
  
  .hero .read-more-link:after {
    border-bottom: 2px solid #fff;
  }
  
  .hero p {
    color: #fff;
    text-align: center;
    text-shadow: 0 0 40px rgba(0, 0, 0, 0.96);
  }
  
  .hero span.fat-text {
    font-weight: 600;
    font-size: 2rem;
    margin-bottom: 1.7rem;
    margin-top: 13.2rem;
    letter-spacing: 0;
    padding: 0 40px;
  }
  
  .hero span.thin-text {
    font-weight: 300;
    font-size: 2rem;
    margin-bottom: 7.8rem;
    padding: 0 40px;
  }
  
  /* HERO MEDIA QUERIES */
  
  @media only screen and (min-width: 52em) {
    .hero span.fat-text {
      margin-top: 15.2rem;
      padding: 0;
    }
   
    h1.hero-single-business-text {
      font-size: 3.2rem;
      line-height: 4.6rem;
      margin-top: 9rem;
    }
  
    .hero .read-more-link {
      margin-top: 2.5rem;
      color: #fff;
      margin-bottom: 6rem;
    }
  }
  
  @media only screen and (min-width: 64em) {
    
  
    .hero.single-business {
      padding-bottom: 0;
      height: auto;
      position: relative;
    }
  
    .hero.single-business .hero-background {
      position: relative;
    }

  
    h1.hero-text {
      font-size: 6rem;
      margin: 0 auto 35px auto;
    }
    .hero span.fat-text {
      margin-top: 14.2rem;
      margin-bottom: 1rem;
    }
   
    h1.hero-single-business-text {
      font-size: 4rem;
      line-height: 5.4rem;
      margin-top: 11.5rem;
    }
  
    .hero .read-more-link {
      margin-bottom: 9.3rem;
    }
  }
  