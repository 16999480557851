@import "../../framework/common.scss";
@import "../../framework/styleguide.scss";

.search-bar {
    width: 100%;
    z-index: 90;
    position: relative;
    line-height: 1;
    padding: 0 20px;
    padding-top: 0;
    padding-bottom: 0;
    overflow: visible;

    &--is-search-page {
        margin-bottom: 0;
        padding:0;

        .search-bar__button {
            margin-bottom:0;
        }
    }

    @include breakpoint(large) {
        bottom: -35px;
        position: absolute;
        left: 0;
        padding: 0 10.416666667%;

        &--is-search-page {
            padding:0;
            position: relative;
            bottom: 0;
        }
    }

    &__shadow {
        width: 1140px;
        max-width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: 0 auto;
        position: relative;

        .search-bar--is-search-page & {
            width:auto;
        }
        
        @include breakpoint(large) {
            flex-direction: row;
            box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
        }
    }

    &__q {
        -moz-appearance: none;
        -webkit-appearance: none;
        font-size: 1.6rem;
        margin-bottom: 1.6rem;
        margin-left:0;
        margin-right:0;
        width: 470px;
        max-width: 100%;
        box-shadow: 0 1px 4px 0 rgba($black, 0.3);
    
        @include breakpoint(large) {
            height: 70px;
            font-size: 1.8rem;
            padding: 24px;
            margin-bottom: 0;
            width: auto;
            box-shadow: none;
            border-radius: 3px 0 0 3px;
            flex: 1 1 auto;
            /* Fix for Chrome 44 bug. https://code.google.com/p/chromium/issues/detail?id=506893 */
            min-width: 0;
            min-height: 0;
        } 
    }

    &__icon {
        width: 30px;
        height: 30px;
        background-repeat: no-repeat;
        background-position: center center;
        display: inline-block;
        vertical-align: middle;
        background-size: 60%;
        background: rgba($white, 0.38) url("../../images/search.svg") no-repeat center;
        border-radius: 50%;
        margin-right: 1.4rem;

        @include breakpoint(large) {
            width: 38px;
            height: 38px;
        }
    }

    &__button {
        border-radius: 3px;
        font-size: 1.5rem;
        height: 50px;
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 2.3rem;
        margin-bottom: 3.2rem;
        margin-left:0;
        margin-right:0;
        width: 470px;
        max-width: 100%;
        box-shadow: 0 1px 4px 0 rgba($black, 0.3);
        position: relative;

        &:hover {
            box-shadow: none;
        }

        span {
            display: inline-block;
            align-self: center;
            
            span {
                color: $black;
            }
        }

        &-text {
            opacity:1;
            transition: opacity 0.3s ease-in-out;
            .search-bar--is-loading & {
                opacity: 0;
            }
        }

        .spinner {
            opacity:0;
            top: 16px;
            transition: opacity 0.3s ease-in-out;

            @include breakpoint(large) {
                top: 27px;
            }
            
            .search-bar--is-loading & {
                opacity: 1;
            }
            span {
                height:14px;
                width:14px;
            }
        }
    
        @include breakpoint(large) {
            height: 70px;
            margin-bottom: 0;
            justify-content: flex-start;
            width: auto;
            box-shadow: none;
            border-radius: 0 3px 3px 0;

            .search-bar--is-search-page & {
                width: auto;
                align-items: center;
                justify-content: center;
            }
        }
      }
  }