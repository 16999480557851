@import '../../framework/helpers.scss';
@import '../../framework/common.scss';

.tagline {
    @include fit-content();
    text-align: center;

    &__wrapper {
        @include content-padding();
        padding-bottom: 0;

        @include breakpoint(medium) {
            padding-bottom: 0;
        }
    }

    h2 {
        margin-top: 1.3rem;
        margin-bottom: 1.6rem;

        textarea {
            width:auto;
            text-align: center;
        }
    }
    
    p {
        max-width: 90%;
        width: 54.9rem;
        margin: 0 auto;
    }

    &--big {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5.7rem;

        @include breakpoint(large) {
            margin-top: 8.6rem;
        }

        p {
            max-width: 90%;
            width: 26.5rem;
            font-size: 2.6rem;
            line-height: 1.3;
            font-weight: 300;
            color: $dabas-blue;
            text-align: center;
            margin: 0;

            strong {
                font-weight: 400;
            }

            @include breakpoint(medium) {
                width: 62.2rem;
                font-size: 2.6rem;
                font-size: 3.2rem;
            }
        }

        .tagline__wrapper {
            padding:0;
        }
    }
}

.marketers-info--gray {
    + .tagline {
        &--big {
            .tagline__wrapper {
                padding-top:57px;

                @include breakpoint(medium) {
                    padding-top:86px;
                }
            }
        }
    }
}

/*
p {
    max-width: 90%;
    width: 26.5rem;
    font-size: 2.6rem;
    line-height: 1.3;
    font-weight: 300;
    color: $dabas-blue;
    text-align: center;
    margin: 0;

    @include breakpoint(medium) {
        width: 62.2rem;
        font-size: 2.6rem;
        font-size: 3.2rem;
    }

    span {
        f
    }
}*/