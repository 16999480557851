@import '../../framework/common.scss';
@import '../../framework/styleguide.scss';
@import '../../framework/helpers.scss';
.post {
    @include fit-content();
    position: relative;
    &__preamble {
        @include preamble();
    }

    &__wrapper {
        @include content-padding();
        padding-top: 0;

        @include breakpoint(xmedium) {
            padding-top: 56px;
        }
    }

    &__image {
        width: calc(100% + 40px);
        margin: 0 -20px 3rem -20px;

        @include breakpoint(xmedium) {
            width: 29.561403508%;
            margin: 0;
            order: 99999;
        }

        @include breakpoint(xxmedium) {
            margin-right: 4.736842105%;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    &__content {
        @include breakpoint(xmedium) {
            width: 54.385964912%;
        }

        @include breakpoint(xxmedium) {
            margin-left: 4.736842105%;
        }

        img {
            max-width: 100%;
        }

        a {
            color:$darkish-blue;    
        }
    }

    &__meta {
        padding: 1.8rem 0;
        border-top: 1px solid #f0f0f0;
        border-bottom: 1px solid #f0f0f0;
        margin: 2rem 0;
        font-size: 1.5rem;
        line-height: 15px;
        display: flex;
        align-items: center;

        img {
            width: 3.6rem;
            height: auto;
            border-radius: 50%;
            margin-right: 1.2rem;

            @include breakpoint(xmedium) {
                width: 5.6rem;
                margin-right: 1.7rem;
            }
        }

        &-author {
            margin-right: 1.7rem;
        }

        &-calendar {
            display: flex;

            &:before {
                content: "";
                width: 25px;
                height: 13px;
                align-self: center;
                background-size: 25px 13px;
                background-image: url("../../images/calendar-icon.svg");
                background-repeat: no-repeat;
            }
        }
    }

    &__tags {
        padding-top: 3rem;
        display: flex;

        &-list {
            display: flex;
            flex-wrap: wrap;
        }

        &-icon {
            &:before {
                content: "";
                display: flex;
                margin-top: 4px;
                width: 18px;
                height: 18px;
                align-self: center;
                background-size: 18px 18px;
                background-image: url("../../images/tag.svg");
                background-repeat: no-repeat;
            }
        }

        a {
            margin-left: 12px;
            font-weight: 600;
            font-size: 1.4rem;
            color: $darkish-blue;
            align-self: center;
        }
    }

    &__share {
        @include fit-content();
    
        &__content {
            padding: 20px 0 0;
        }
    
        &__content-inner {
            
            display: flex;
        }

        &__icon {
            background-color: $very-light-pink;
            border-radius:50%;
            display: block;
            height:40px;
            margin-right:10px;
            width:40px;

            &--twitter,
            &--facebook,
            &--linkedin,
            &--mail {
                background-repeat: no-repeat;
                background-size: 20px 20px;
                background-position: center center;
            }

            &--twitter {
                background-image: url('../../images/twitter.svg');
            }

            &--facebook {
                background-image: url('../../images/facebook.svg');
            }

            &--linkedin {
                background-image: url('../../images/linkedin.svg');
            }

            &--mail {
                background-image: url('../../images/mail.svg');
            }
        }
    }

    ul,
    ol {
        margin:0 2rem 2.6rem 2rem;
    }

    @include breakpoint(xmedium) {
        & > div {
            display: flex;
            justify-content: space-between;
        }
    }

    &__content-content {
        strong {
            font-weight: bold;
        }
        em {
            font-style: italic;
        }
        u {
            text-decoration: underline;
        }

        h1, h2 {
            margin-bottom:1.6rem;
        }

        h3 { 
            color:#0b3358;
            font-weight: bold;
            margin-bottom: 0.8rem;
        }
    }
}