@import '../../framework/styleguide.scss';
@import '../../framework/helpers.scss';

.pricing {
    border-top: 1px solid rgba($brown-grey, 0.15);

    @include fit-content();

    &__wrapper {
        @include content-padding();
    }

    &__options {
        margin-bottom: 26px;
        /*border-top: 1px solid rgba($brown-grey, 0.15);*/
    }

    &__option {
        padding: 1.6rem 0;
        border-bottom: 1px solid rgba($brown-grey, 0.15);
        display: flex;

        &:first-child {
            .pricing__heading {
                margin-top:0;
            }
        }

        &--air {
            border-bottom:none;
        }

        &--is-heading {
            & + .pricing__option--air {
                padding:0;
            }
        }
    }

    &__text {
        margin-right: 4.5rem;
        
        //WP
        .block-editor & {
            min-width: 540px;
        }
    }

    &__heading {
        font-weight: bold;
        font-size: 1.8rem;
        margin-top:3.2rem;
    }

    &__price {
        margin-left: auto;
        font-weight: 600;
        flex: 0 0 auto;
    }

    &__header {
        display: flex;
        border-bottom: 1px solid rgba($brown-grey, 0.15);
        padding: 10px 0;
    }

    &__subheading {
        font-weight: bold;
        font-size: 1.8rem;
        margin-bottom: 1rem;
    }

    &__th,
    &__td {
        font-weight: bold;
        font-size: 1.8rem;
    }

    &__td {
        margin-left: auto;
    }

    &__footer  {
        p {
            font-size: 1.4rem;
            font-style: italic;
            margin-bottom: 1.4rem;
        }
    }
}