/* SEARCH PAGE WATCH NOTIFICATION BANNER */

.search-page-watch-notification-banner {
    background-color: #0b3358;
    width: 100%;
    color: #fff;
    border-radius: 3px;
    padding: 20px 22px 20px 27px;
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
  }
  
  .search-page-watch-notification-banner div span {
    font-weight: 600;
    display: block;
  }
  
  .search-page-watch-notification-banner .options {
    display: flex;
    align-items: center;
    margin-top: 2.2rem;
  }
  
  .search-page-watch-notification-banner .options a,
  .search-page-watch-notification-banner .options button {
    background-color: transparent;
    border: none;
    color: #79bafa;
    cursor: pointer;
    font-size: 1.5rem;
    text-decoration: none;
    padding:0;
    font-weight: 600;
  }
  
  .search-page-watch-notification-banner .options span {
    margin: 0 1.3rem;
    width: 1px;
    height: 30px;
    background-color: #3d5d7a;
  }
  
  /* SEARCH PAGE WATCH NOTIFICATION BANNER MEDIA QUERIES */
  
  @media only screen and (min-width: 40em) {
    .search-page-watch-notification-banner {
      align-items: center;
      flex-direction: row;
    }
  
    .search-page-watch-notification-banner div span {
      display: inline;
    }
  
    .search-page-watch-notification-banner .options {
      margin-left: auto;
      padding-left: 2rem;
      margin-top: 0;
      align-self: flex-start;
    }
  }