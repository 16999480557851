@import '../../framework/common.scss';
@import '../../framework/helpers.scss';
@import '../../framework/styleguide.scss';

.quote {

    &__wrapper {
        @include fit-content();
    }

    &__wrapper-inner {
        @include content-padding();
        @include breakpoint(xmedium) {
            display: flex;
        }
    }

    &__fact {
        font-weight: 600;

        @include breakpoint(xmedium) {
            width: 31%;
            padding-right: 9.298245614%;
        }
    }

    &__fact-percentage {
        font-size: 7rem;
        color: $reddish-orange;
        border-top: 7px solid $reddish-orange;
        line-height: 1.4;
    }

    &__fact-text {
        margin-top: 0.4rem;
        border-bottom: 1px solid #f0f0f0;
        margin-bottom: 2.6rem;

        @include breakpoint(xmedium) {
            border-bottom: none;
            margin-bottom: 0;
        }
    }

    &__text {
        width:69%;
    }

    &__preamble {
        font-weight: 400;
        font-size: 2rem;
        color: $brown-grey-two;
        letter-spacing: 0;
        line-height: 3.2rem;
        margin-top: 0;        
        margin-bottom: 3.3rem;  
    }

    &__author {
        display: flex;
        align-items: center;
    }

    &__author-details {
        display: flex;
        flex-direction: column;
        line-height: 2.2rem;
    }

    &__author-title {
        font-weight: 300;
    }
    
    img {
        width: 57px;
        height: auto;
        border-radius: 50%;
        margin-right: 1.3rem;

        @include breakpoint(xmedium) {
            width: 95px;
        }
    }
} 