@import '../../framework/styleguide.scss';
.cookie-banner {
    background-color: rgba($white, 0.21);
    width: 100%;
    color: $white;
    border-radius: 3px;
    font-size: 1.4rem;
    margin-top: 4.6rem;
    padding: 20px 22px 20px 27px;
    display: flex;
    opacity: 1;
    transition: opacity 0.3s ease;

    &--hiding {
        opacity: 0;
    }

    &__close-link {
        background: transparent;
        border:none;
        cursor: pointer;
        margin-left: auto;
        padding:0;
    }

    a {
        color: $sky-blue;
        text-decoration: none;
    }
}