@import '../../framework/common.scss';
@import '../../framework/styleguide.scss';
.sort {
    flex: 1;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 98;

    &--is-open {
        box-shadow: 0 1px 5px 0 rgba($black, 0.2);
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;

        .sort__sorting-button {
            background-color: $white;

            &:after {
                transform: rotate(180deg);
            }
        }

        .button-small {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }

        .sort__options {
            background-color: $white;
        }
    }

    &__sorting-button {
        background-color: transparent;
        cursor: pointer;
        transition: background-color 0.2s ease-in;
        width: 100%;
        display: flex;
        font-size: 100%;
        font-weight: 400;
        padding:10px 18px 11px;

        &:after {
            content: "";
            width: 10px;
            height: 7px;
            background-size: 10px 7px;
            background-repeat: no-repeat;
            align-self: center;
            background-image: url("../../images/arrow-down.svg");
            margin-left: auto;
        }
    }

    &__options {
        background-color: transparent;
        overflow: hidden;
        border-left: 1px solid $very-light-pink-four;
        border-right: 1px solid $very-light-pink-four;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        transition: height 0.2s ease-in-out, background-color 0.2s ease-in;
    }

    &__option {
        display: flex;
        padding: 9px 14px;
        border-bottom: 1px solid $very-light-pink-four;

        &:hover {
            background-color: $reddish-orange;
            color: $white;
            cursor: pointer;
        }
    }

    &__option-label {
        cursor: pointer;
        width: 100%;
        display: flex;
        align-items: center;
    }
}