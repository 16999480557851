@import '../../framework/styleguide.scss';
@import '../../framework/common.scss';
@import '../../framework/helpers.scss';

.newsroom-contact-person {
    text-align: center;

    @include fit-content();

    @include breakpoint(xmedium) {
        text-align: left;
    }

    &__wrapper-outer {
        
        @include content-padding();

        .instagram + .newsroom-contact-person &,
        .post + .newsroom-contact-person & {
            padding: 0 20px 57px 20px;

            @include breakpoint(medium) {
                padding: 0 10.416666667% 86px 10.416666667%;
            }
        }
    }

    &__wrapper {
        @include breakpoint(xmedium) {
            margin-left:-5rem;
            display: flex;
        }
    }

    &__person {
        line-height: 2.3rem;
        color: $dabas-blue;
        margin-bottom: 6rem;

        @include breakpoint(xmedium) {
            margin-left:5rem;
            width: 25.9rem;
        } 

        img {
            margin-bottom: 1.2rem;
            max-width:100%;

            @include breakpoint(xmedium) {
                margin-bottom: 4.2rem;
                
            }
        }

        a {
            color:$dabas-blue;
        }
    }

    &__name {
        font-weight: 600;
        font-size: 17px;
    }

    &__title {
        font-weight: 300;
        color: $brown-grey-three;
        margin-bottom: 0.8rem;
    }

    &__delete {
        background-color: transparent;
        background-image: url('../../images/trash-alt-regular.svg');
        background-repeat: no-repeat;
        background-size: 16px 16px;
        background-position: left center;
        border:none;
        cursor: pointer;
        color:$dusty-red;
        margin-top:8px;
        padding-left:20px;
        
    }

    h2 {
        .post + .newsroom-contact-person & {
            border-top:solid 1px $very-light-pink;
        }
    }
    
    .post + & {
        h2 {
            border-top:solid 1px $very-light-pink;
            padding-top:57px;

            @include breakpoint(large) {
                padding-top:86px;
            }
        }  
    }
}