@import '../../framework/styleguide.scss';
@import '../../framework/helpers.scss';

.news-list-container {
    background: $off-white;

    &--white {
        background:$white;
    }

    &__wrapper-inner {
       @include content-padding();

       .news & { 
          padding-bottom: 0;
          padding-top:0;
       }
    }    

    &__wrapper {   
        @include fit-content();
    }

    &__read-more-link {
        @include  read-more-link();
    }
}

  