@import './editor.scss';
// Breakpoints
$large: 64em;
$xxxmedium: 1150px;
$xxmedium: 60em;
$xmedium: 52em;
$medium: 40em;
$small: 300px;

@mixin breakpoint($size) {
    @if $size == "small" {
        @media (min-width: $small) {
            @content;    
        }
    }
    
    @if $size == "medium" {
        @media (min-width: $medium) {
            @content;    
        }
    }

    @if $size == "xmedium" {
        @media (min-width: $xmedium) {
            @content;    
        }
    }

    @if $size == "xxmedium" {
        @media (min-width: $xxmedium) {
            @content;    
        }
    }

    @if $size == "xxxmedium" {
        @media (min-width: $xxxmedium) {
            @content;    
        }
    }
 
    @if $size == "large" {
        @media print, (min-width: $large) {
            @content;    
        }
    }
}
