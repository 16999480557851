@import '../../framework/styleguide.scss';

.breadcrumb {
    padding: 1rem 4.5%;
    line-height: 1;

    &--gray {
        background: $off-white;
    }   

    &__item {
        font-size: 1.3rem;
        color: $darkish-blue;
        text-decoration: none;
        font-weight: 400;

        &:before {
            content: "";
            display: inline-block;
            margin-right: 0.7rem;
            width: 4px;
            height: 8px;
            background-size: 4px 8px;
            background-image: url("../../images/arrow-left.svg");
            background-repeat: no-repeat;
        }

        &:hover {
            text-decoration: underline;
        }


    }

    &__list {
        display: flex;
        list-style: none;
        margin:20px 0;
        padding:0;

        li {
            &:last-child {
                a {
                    &:after {
                        content:'';
                    }
                }
            }
        }
    }

    &__listitem {
        color:$darkish-blue;
        display: inline-block;
        font-size: 1.3rem;
        font-weight: 400;
        text-decoration: none;

        &:hover {
            span {
                text-decoration: underline;
            }
        }

        &:after {
            content:'/';
            margin:0 6px;
        }

        &--selected {
            color: $brown-grey-two;
        }
    }
}
