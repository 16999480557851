@import "../../framework/styleguide.scss";
@import "../../framework/common.scss";
@import "../../framework/helpers.scss";
.news-room-container {
    margin-top:-37px;

    .is-preview & {
        margin-top:0;
    }
}

.newsroom-toggle-products {
    
    &__container {
        display: flex;
        justify-content: flex-end;
    }
}

.newsroom-products {
    background-color: $off-white;
    position: relative;

    &--is-loading {
        min-height: 100vh;
    }

    &__header {
        @include fit-content();
    }

    &__header-inner {
        @include content-padding();
        padding-bottom:0;

        @include breakpoint(large) {
            padding-bottom:0;
        }
    }

    &__async {
        opacity: 1;
        transition: all 0.3s ease;

        .newsroom-products--is-loading & {
            opacity: 0;
        }
    }

    &__no-products {
        border-bottom: 1px solid rgba(151, 151, 151, 0.2);
        color:$black;
        font-size: 2rem;
        font-weight: 300;
        padding-bottom: 130px;
    }
}

.preview-lock {
    background-color: #fff;
    opacity: 0;
    bottom:0;
    cursor: zoom-in;
    left:0;
    position: fixed;
    right:0;
    top:0;
    z-index: 10000;
}
.is-preview {
    body {
        zoom:0.8
    }

    &.zoom {
        body {
            zoom:1;
        }

        .preview-lock {
            cursor: zoom-out;
        }
    }
}