@import '../../framework/styleguide.scss';
.uhm {
    &__product-header {
        margin-bottom: 50px;
        margin-top: 50px;
    }

    &__heading {
        color:$text-black;
        margin-bottom:10px;
    }

    &__file {
        background-image:url('../../images/file-pdf-regular.svg');
        background-repeat: no-repeat;
        background-size: 12px;
        background-position: left center;
        padding-left: 18px;
    }

    &__table-container {
        overflow-x: auto;
        width:100%;

    }

    &__table-heading {
        color: #999;
        font-size: 1.2rem;
        font-weight: bold;
        letter-spacing: 1.2px;
        margin:30px 0 10px;
        text-transform: uppercase;
    }
}