@import "../../framework/styleguide.scss";

.news-banner {
    width: 100%;
    background-color: #fff;
    text-align: center;
    font-size: 1.4rem;
    padding: 19px 10.416666667%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    a {
        text-decoration: none;
        color: $dabas-blue;
    }

    &__label {
        font-weight: 600;
        color: $tomato;
        letter-spacing: 1.08px;
        text-transform: uppercase;
        margin-right: 0.4rem;
        display: inline;

        .block-editor & {
            display: block;
            float: left;
        }
    }
  }