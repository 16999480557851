@import '../../framework/styleguide.scss';

.filter-items {
    display: flex;
    flex-direction: column;
    padding-bottom: 132px;

    &__clear-filters {
      background:transparent;
      border:none;
      font-size: 1.4rem;
      color: $text-black;
      cursor: pointer;
      align-self: center;
      border: 1px solid #d6d6d6;
      padding: 13px 14px 14px!important;
      border-radius: 3px;
      width: 90%;
      font-weight: 400;
      transition: all 0.3s ease;

      &:hover {
        box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.2);
      }
    }
  
}
  
.filter-item {
    display: flex;
    flex-direction: column;
    background-color: $white;
    border-bottom: 1px solid $very-light-pink-three;
   
    &__sub-label {
      border-bottom: 1px solid $very-light-pink-three;
      padding-bottom: 9px;
    }

    &__label-info {
      display: flex;
      position: relative;
    }

    h4 {
        background: $white;
        font-weight: 600;
        font-size: 1.6rem;
        color: $dabas-blue;
        display: flex;
        padding: 20px;
        transition: opacity 0.3s ease-in-out;
        opacity: 1;

        &:after {
          content: "";
          width: 10px;
          height: 7px;
          background-size: 10px 7px;
          background-repeat: no-repeat;
          align-self: center;
          background-image: url("../../images/arrow-down.svg");
          margin-left: auto;
          order: 3;
        }

        &:hover {
          cursor: pointer;
        }

        .search-page-filter-bar--is-loading & {
          opacity: 0.2;
        }
    }

    &--is-open {
      background-color: transparent;

      .filter-item__child {
        overflow: visible;
      }

      h4 {
        background: transparent;

        &:after {
          transform: rotate(180deg);
        }
      }
    }

    &--is-active {
      h4 {
        &:before {
          content: "";
          width: 9px;
          height: 9px;
          background-size: 9px 9px;
          background-repeat: no-repeat;
          order: 2;
          align-self: center;
          margin-left: 0.5rem;
          background-image: url("../../images/filter-active.svg");
        }
      }
    }

    &__child {
      height: 0;
      overflow: hidden;
      -webkit-transition: height 0.2s ease-in-out;
      -moz-transition: height 0.2s ease-in-out;
      -ms-transition: height 0.2s ease-in-out;
      -o-transition: height 0.2s ease-in-out;
      transition: height 0.2s ease-in-out;
    }

    &__child-inner {
      padding: 0 20px 20px 20px;
      transition: opacity 0.3s ease-in-out;
      opacity: 1;

      .search-page-filter-bar--is-loading & {
        opacity: 0.2;
      }

      label {
        margin-bottom: 1.2rem;
        display: flex;
        position: relative;
      }
    }

    &__ingredients-input {
      box-shadow: 0 1px 4px 0 rgba($black, 0.14);
      width: 60.714285714%;
    }

    &__add-ingredients {

      & > div {
        display: flex;
        justify-content: space-between;
      }
    }

    &__add-btn {
      padding: 13px 18px;
    }

    &__select {
      display: block;
      font-size: 16px;
      font-family: "Open Sans", sans-serif;
      font-weight: 400;
      color: #9b9b9b;
      line-height: 1.3;
      padding: 0.6em 1.4em 0.5em 0.8em;
      width: 100%;
      max-width: 100%;
      box-sizing: border-box;
      margin: 0 0 16px 0;
      border: none;
      box-shadow: 0 1px 4px 0 rgba($black, 0.14);
      border-radius: 3px;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      background-color: #fff;
      background-image: url("../../images/arrow-down-select.svg"),
        linear-gradient(to bottom, $white 0%, $white 100%);
      background-repeat: no-repeat, repeat;
      background-position: right 0.7em top 50%, 0 0;
      background-size: 10px 6px, 100%;

      &::-ms-expand {
        display: none;
      }

      &:hover {
        border-color: #888;
      }

      &:focus {
        border-color: #aaa;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
        box-shadow: 0 0 0 3px -moz-mac-focusring;
        color: $text-black;
        outline: none;
      }

      option {
        font-weight: normal;
      }

      &:disabled,
      &[aria-disabled="true"] {
        color: #f8f8f8;
        background-image: url("../../images/arrow-down-select-disabled.svg"),
          linear-gradient(to bottom, #f8f8f8 0%, #f8f8f8 100%);
        
        &:hover {
          border-color: #aaa;
        }
      }   
      
      &--multiple {
        background-image: none;
        padding: 0.6em 0.8em 0.5em 0.8em;
      }
    }
}