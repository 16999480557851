@import '../../framework/common.scss';
@import '../../framework/helpers.scss';
@import '../../framework/styleguide.scss';

.usp {
    background: $off-white;
    height: 100%;

    &__outer-wrapper {
        max-width: 100%;
        margin: 0 auto;
        width: 1440px;
    }

    &__wrapper {
        display: flex;
        justify-content: center;
        align-items: stretch;
        flex-wrap: wrap;
        padding: 57px 20px;

        @include breakpoint(medium) {
            padding: 57px 10.416666667%;
        }

        @include breakpoint(large) {
            padding-top: 115px;
            padding-bottom: 77px;
        }
    }

    &__left, 
    &__right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        text-align: center;

        @include breakpoint(xxmedium) {
            width: 36rem;
            max-width: 100%;
        }
    }

    &__left {
        padding-bottom: 4.7rem;
        margin-bottom: 4.7rem;
        border-bottom: 1px solid $very-light-pink;

        @include breakpoint(xxmedium) {
            margin-right: 3.947368421%;
            padding: 0;
            margin-bottom: 0;
            border: none;
        }
    }

    &__right {
        @include breakpoint(xxmedium) {
            margin-left: 3.947368421%;
        }
    }

    &__read-more-link {
        @include read-more-link();
    }

    img {
        height: 70px;
        max-width: 100%;
    }

    p {
        font-size: 1.8rem;
        width: 36rem;
        max-width: 100%;
        margin: 1.9rem 0 3.7rem 0;
    }

    a {
        margin-top: auto;
    }

    //WP
    textarea {
        text-align: center;
    }
}