@import '../../framework/common.scss';
@import '../../framework/helpers.scss';

.partner-examples {
    text-align: center;

    &__wrapper-outer {
         @include content-padding();
    }

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    &__read-more-link {
        @include read-more-link();
        margin-top: 3.1rem;

        @include breakpoint(large) {
            margin-top: 6.1rem;
        }
    }

    img {
        height: 1.6rem;
        width: auto;
        margin: 0 1.9rem 2.3rem 0;

        @include breakpoint(xmedium) {
            height: 2.5rem;
            margin-right: 2.7rem;
        }

        @include breakpoint(large) {
            height: 3.4rem;
            margin-right: 3.4rem;
        }
    }
}
  