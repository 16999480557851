@import '../../framework/styleguide.scss';
@import '../../framework/common.scss';
.newsroom-edit {

    display: flex;
    flex-direction: column;
    position: relative;

    &__edit-btn {
        border:solid 2px $text-black;
        border-radius:50%;
        background-color: transparent;
       
        cursor: pointer;
        height: 50px;
        left:40px;
        position: absolute;
        transition: all 0.3s ease;
        top:40px;
        width:50px;
        z-index: 200;

        &--news-item {
            left:10px;
        }

        &--delete {
            top:100px;
        }

        .newsroom-edit--logo & {
            top:-40px;
            left:-60px;
        }

        &--top {
            top:100px;
        }

        &-icon {
            background-image: url('../../images/pencil-black.svg');
            background-position: center center;
            background-size: 20px 20px;
            background-repeat: no-repeat;
            display:block;
            height:48px;
            left:0;
            width:48px;
            position: absolute;
            top:0;
            transition: all 0.3s ease;

            &--rss {
                background-image: url('../../images/rss-black.svg');  
            }

            &--delete {
                background-image: url('../../images/trash-alt-regular-black.svg');
            }
        }

        &-text {
            text-transform: uppercase;
            text-decoration: none;
            font-size: 1.3rem;
            font-weight: 600;
            letter-spacing: 1px;
            display: inline-block;
            color: $text-black;
            margin-left:40px;
            transition: all 0.3s ease;
            opacity: 0;
            white-space: nowrap;
        }

        &:hover {
            
            background-color: rgba($text-black, 0.05);

            .newsroom-edit__edit-btn-icon {
                transform: rotate(-25deg);

                &--rss {
                    transform: scale(1.2);                    
                }
            }

            .newsroom-edit__edit-btn-text {
                opacity:1;
                transform: translateX(10px);
            } 
        }
    }

    &--no-content {
        background: $off-white;
        border: 2px dashed $very-light-pink;
        margin-bottom:50px;
        margin-top:50px;
        padding: 10rem 3rem;
        text-align: center;
        
        .newsroom-edit__toolbar {
            display: block;
            padding: 0;
        }
    }

    &--logo {
        margin-bottom: 0;
        margin-top:0;
        padding:2rem 1rem;
    }

    &--news {
        margin-top:0;

        .newsroom-edit__edit-btn {
            border-color:$white;
            &:hover {
                background-color: rgba($white, 0.1);
            }

            &-icon {
                background-image: url('../../images/pencil-white.svg');

                &--rss {
                    background-image: url('../../images/rss-white.svg');  
                }
            }

            &-text {
                color:$white;
            }
        }
    }

    &--no-bgcolor {
        .newsroom-edit__edit-btn {
            border-color:$text-black;
            &:hover {
                background-color: rgba($text-black, 0.1);
            }

            &-icon {
                background-image: url('../../images/pencil-black.svg');

                &--rss {
                    background-image: url('../../images/rss-black.svg');  
                }
            }

            &-text {
                color:$text-black
            }
        }
    }

    &--info {
        margin-bottom:0;
        margin-top:0;
        height: 100%;
    }

    &--video.newsroom-edit--no-content,
    &--globe.newsroom-edit--no-content,
    &--contact.newsroom-edit--no-content {
        max-width: 1140px;
        margin-left: auto;
        margin-right: auto;
    }

    &--instagram.newsroom-edit--no-content {
        max-width: 1400px;
        margin-left: auto;
        margin-right: auto;
    }

    &__heading {
        color:$text-black;
        font-weight: 400;
        margin-bottom:10px;
        padding-top:60px;

        .newsroom-edit--news &,
        .newsroom-edit--video &,
        .newsroom-edit--globe &,
        .newsroom-edit--instagram &,
        .newsroom-edit--contact &,
        .newsroom-edit--info & { 
            background-repeat: no-repeat;
            background-position: top center;
        }

        .newsroom-edit--news & {
            background-image: url('../../images/rss-black.svg');
            background-size: 40px;
        }

        .newsroom-edit--video & {
            background-image: url('../../images/youtube.svg');
            background-size: 60px;
        }

        .newsroom-edit--globe & {
            background-image: url('../../images/globe.svg');
            background-size:50px;
        }

        .newsroom-edit--instagram & {
            background-image: url('../../images/instagram.svg');
            background-size:50px;
        }

        .newsroom-edit--contact & {
            background-image: url('../../images/user-plus.svg');
            background-size:60px;
        }

        .newsroom-edit--info & { 
            background-image: url('../../images/info-icon-muted.svg');
            background-size: 50px;
        }
    }

    &__toolbar {
        display:none;
        left: 0;
        padding: 20px 104px;
        text-align: center;
        right: 0;


        button {
            background-color: $white;
            border: 1px solid $dabas-blue;
            border-radius: 4px;
            font-weight: 600;
            font-size: 1.3rem;
            color: $dabas-blue;
            cursor: pointer;
            text-align: center;
            padding: 1rem 4rem;
            text-decoration: none;
            margin-left:1rem;
            margin-right:1rem;
            transition: box-shadow 0.2s ease;
    
            @include breakpoint(xmedium) {
                padding: 0.9rem 2rem;
                font-size: 1.5rem;
            }

            &:hover,
            &:focus {
                background: $dabas-blue;
                box-shadow: 0 0 16px 0 rgba($black, 0.4);
                color:$white;
            }

            /*background: #fff;
            background-repeat: no-repeat;
            background-position: 10px;
            border: 1px solid #d8d8d8;
            border-radius: 3px;
            cursor: pointer;
            display: inline-block;
            font-size: 1.5rem;
            font-weight: 400;
            margin-right:20px;
            padding: 9px 12px 9px 12px;
            text-align: left;
            transition: box-shadow .2s ease;
            width: auto;*/
        }
    }
}