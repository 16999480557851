@import '../../framework/styleguide.scss';
@import '../../framework/common.scss';
@import '../../framework/helpers.scss';

.newsroom-menu {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include fit-content();

    @include breakpoint(xmedium) {
        flex-direction: row;
        height: 11.3rem;
    }

    &__logo,
    &__follow {
        width: 30rem;
        max-width: 90%;
        display: flex;
        justify-content: center;

        @include breakpoint(xmedium) {
            width: 20%;
        }
    }

    &__logo {
        font-size: 25px;
        font-weight: 300;

        margin: 11rem 0 0.5rem 0;
        
        @include breakpoint(xmedium) {
            margin: 0;
        }

        img {
            align-self: flex-start;
            height: auto;
            max-width: 70%;
            max-height:80px;
        }
    }

    &__follow {
        display: none;

        @include breakpoint(xmedium) {
            display: flex;
        }
    }

    &__navigation {
        width: 100%;
        display: flex;
        justify-content: center;
        height: 100%;
        order: 3;

        @include breakpoint(xmedium) {
            order: 0;
            width: 60%;
        }

        a {
            text-decoration: none;
            color: $dabas-blue;
            padding: 0 1.6rem;
            height: 8.1rem;
            font-size: 1.4rem;

            @include breakpoint(xmedium) {
                padding: 0 2.5rem;
                height: auto;
                font-size: initial;
            }
        }
    }

    &__link-item {
        span {
            position: relative;
            height: 100%;
            display: flex;
            align-items: center;
            /*
            &:after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                height: 100%;
                width: 100%;
                border-bottom: 10px solid $white;
            }*/
        }
        
        &--active {
            span {
                font-weight: bold;
                /*&:after {
                    border-bottom: 10px solid $dabas-blue
                }*/
            }
        }
        &:hover {
            text-decoration: underline;
        }
    }

    &__follow-button {
        background-color: transparent;
        border: 1px solid $dabas-blue;
        border-radius: 4px;
        cursor: pointer;
        font-weight: 600;
        font-size: 1.3rem;
        color: $dabas-blue;
        text-align: center;
        padding: 1rem 4rem;
        text-decoration: none;
        margin-bottom: 4rem;

        &:hover {
            box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
        }

        @include breakpoint(xmedium) {
            padding: 0.9rem 6.4rem;
            margin: 0;
            font-size: 1.5rem;
        }
    }
}