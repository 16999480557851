@import '../../framework/styleguide.scss';
@import '../../framework/common.scss';
.history {
    border-bottom: solid 3px #d8d8d8;
    padding:30px 0;
    margin:0 0 30px;
    min-height: 150px;
    position: relative;

    @include breakpoint(large) {
        margin:0 0 60px;
        padding:60px 0;
    } 

    @media print {
        display: none;
    }

    p {
        margin-bottom: 0;
    }

    &__heading {
        color:$brown-grey-two;
        font-size: 1.2rem;
        font-weight: bold;  
        letter-spacing: 1.2px;
        text-transform: uppercase;
    }

    &__content {
        padding:30px 0 30px 40px;
        position: relative;
        &:before {
            background-color: $very-light-pink;
            bottom:10px;
            content: '';
            display: block;
            left:10px;
            opacity: 1;
            position: absolute;
            top:10px;
            transition: all 0.3s ease;
            width: 2px;

        }

        .history--is-loading & {
            &:before {
                opacity: 0;
            }
        }
    }
    

    .spinner {
        opacity: 0;
        top:50%;
        transform: translateX(-50%) translateY(-50%);

        span {
            background-color: $black;
        }
    }

    &--is-loading {
        .spinner {
            opacity: 1;
        }
    }

    &__item {    
        margin-top: 60px;
        opacity: 1;
        position: relative;
        transition: all 0.3s ease;

       @include breakpoint(large) {
            margin-top: 80px;
       } 

        &:first-child {
            margin-top:0;
        }

        .history--is-loading & {
            opacity: 0;
        }
    }
    
    &__item-badge {
        background-color: $white;
        border-radius: 50%;
        height:20px;
        left:-39px;
        padding:0 2px;
        position: absolute;
        top:-1px;
        width: 20px;

        svg {
            fill: $brown-grey;
            height:16px;
            width:16px;
        }
    }

    &__item-date {
        background-color: $white;
        border:none;
        color:$brown-grey-two;
        cursor: pointer;
        display: block;
        font-size: 1.2rem;
        font-weight: bold;  
        letter-spacing: 1.2px;
        margin-left:-12px;
        padding-right:20px;
        position: relative;
        text-transform: uppercase;

        &:after {
            background-image: url('../../images/arrow-down-blue.svg');
            background-repeat: no-repeat;
            background-size: 100%;
            content: '';
            display: block;
            height:8px;
            position: absolute;
            right:0;
            top:6px;
            width: 12px;
            transition: all 0.3s ease;
            transform-origin: center center;

            .history__item--is-open & {
                transform: rotate(180deg);
            }
        }

        span {
            color:$peacock-blue;
            text-decoration: underline;
        }

        @media print {
            font-size: 7pt;
        }
    }

    &__item-content {
        display: none;
        padding:40px 0;
        .history__item--is-open & {
            display: block;
        }

        .product-sheet__table {
            margin-top:0;
        }
        
    }
}