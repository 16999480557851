@import '../../framework/common.scss';
@import '../../framework/styleguide.scss';
.autocompleter {
    background: $white;
    font-size: 1.6rem;
    list-style: none;
    margin-bottom: 1.6rem;
    width: 470px;
    max-width: 100%;
    box-shadow: 0 1px 4px 0 rgba($black, 0.3);
    position: absolute;
    text-align: left;
    top: 50px;
    left: 0;

    @include breakpoint(large) {
        font-size: 1.8rem;
        left: 22px;
        right: 40%;
        top: calc(100% - 13px);
        width: auto;
    }

    &__item {
        cursor: pointer;
        display:block;
        padding: 12px 20px;

        &--is-selected,
        &:hover {
            background:$very-light-pink-three;
        }

        mark {
            background: transparent;
            font-weight: bold;
        }
    }
    
    
}