@import '../../framework/styleguide.scss';
@import '../../framework/common.scss';
@import '../../framework/helpers.scss';


.recipesdb {

    &__container {
        @include fit-content();
        padding: 0;
    }

    &__container-inner {
        padding: 0 10.416666667%;
    }

    &__cols {
        align-items: center;
        border-bottom: 1px solid rgba(151, 151, 151, 0.2);
        display: flex;
        padding: 86px 0;
    }

    &__col {        
        
        width:50%;

        &:first-child {
            padding-left: 0;
            padding-right: 20px;   
        }

        &:last-child {
            padding-left: 20px;
        }

        &--no-image {
            text-align: center;
            width:100%;
        }
    }
    
    &__image-container {
        padding-top:56.25%;
        position:relative;
        width:100%;
    }

    &__image {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 100%;
        position: absolute;
        top:0;
        width:100%;
    }
}