@import '../framework/common.scss';
@import '../framework/styleguide.scss';
@import '../framework/typography.scss';
.app-content {
  opacity: 1;
}

.app-below-fold {
  opacity: 1;
}

.editor-styles-wrapper {
  font-family: "Open Sans", sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
}

html.is-paralax, 
html.is-paralax body {
  overflow: hidden;
}

body.single-business {
    padding-top: 53px;
}

/* BODY MEDIA QUERIES  */
@media only screen and (min-width: 64em) {
  body.single-business {
    padding-top: 53px;
  }
}


.content-padding {
  padding: 57px 20px;
  overflow: hidden;
}

.content-padding-no-top {
  padding: 0 20px 57px 20px;
  overflow: visible;
}
.content-padding-no-bottom {
  padding: 57px 20px 0 20px;
  overflow: visible;
}
.content-padding-top-only {
  padding-top: 57px;
}
.content-padding-bottom-only {
  padding-bottom: 57px;
}

@media (min-width: 40em) {
  .content-padding {
    padding: 86px 10.416666667%;
  }
  .content-padding-no-top {
    padding: 0 10.416666667% 86px 10.416666667%;
  }
  .content-padding-no-bottom {
    padding: 86px 10.416666667% 0 10.416666667%;
  }
  .content-padding-top-only {
    padding-top: 86px;
  }
  .content-padding-bottom-only {
    padding-bottom: 86px;
  }
}

.gray-background {
  background-color: #f2f2f1;
}

/* HELPERS */

.force-full-height {
  min-height: calc(100vh - 60px);
}

/* HELPERS MEDIA QUERIES */

@media only screen and (max-width: 40em) {
  .hide-on-mobile {
    display: none;
  }
}

@media only screen and (min-width: 64em) {
  .force-full-height {
    min-height: calc(100vh - 90px);
  }
}

/* IMAGE SHADOWS */

.stripe-shadow,
.dot-shadow {
  position: relative;
  line-height: 1;
  z-index: 1;
  width: 100%;
  margin-bottom: 8.5%;
}

.stripe-shadow img,
.dot-shadow img {
  width: 100%;
  height: auto;
}

.stripe-shadow:after,
.dot-shadow:after {
  content: "";
  position: absolute;
  z-index: -1;
  width: 87.827715355%;
  height: 100%;
}

.stripe-shadow:after {
  background: url(../images/stripe-shadow.svg);
}

.dot-shadow:after {
  background: url(../images/dot-shadow.svg);
}

.shadow-bottom-right {
  margin-right: -20px;
}

.shadow-bottom-right:after {
  bottom: -8.5%;
  right: -5.8%;
  background-position: bottom right;
}

.shadow-bottom-left:after {
  bottom: -8.5%;
  left: -5.8%;
  background-position: bottom left;
}

@media only screen and (min-width: 52em) {
  .stripe-shadow,
  .dot-shadow {
    width: 94.2%;
  }
  .shadow-bottom-right {
    margin-right: 0;
  }
}

/* HEADER */
/* -- moved to react component

/* MENU, LOGIN & WATCH OVERLAY */

.login-overlay,
.watch-overlay,
.watch-overlay-step-2,
.signup-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  left: -100%;
  top: 0;
  background-color: #0b3358;
  display: flex;
  align-items: center;
  padding-top: 75px;
  flex-direction: column;
  opacity: 0;
  -webkit-transition: opacity 0.5s ease;
  -moz-transition: opacity 0.5s ease;
  -ms-transition: opacity 0.5s ease;
  -o-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
}

.login-overlay.open,
.watch-overlay.open,
.watch-overlay-step-2.open,
.signup-overlay.open {
  left: 0;
  opacity: 1;
  -webkit-transition: opacity 0.5s ease;
  -moz-transition: opacity 0.5s ease;
  -ms-transition: opacity 0.5s ease;
  -o-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
}

.menu-overlay button.close-menu,
.login-overlay button.close-login,
.watch-overlay button.close-watch,
.watch-overlay-step-2 button.close-watch-step-2,
.signup-overlay button.close-signup {
  border: none;
  background: transparent;
  padding: 39px;
  position: absolute;
  top: 0px;
  right: 0;
  cursor: pointer;
}

.signup-overlay button.submit {
  width: 100%;
  margin: 0.7rem 0 2.3rem 0;
}

.watch-overlay-step-2 .watch-list-link {
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  width: 100%;
  margin: 0 0 3.6rem 0;
}

.login-content,
.signup-content {
  width: 343px;
  max-width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-content label,
.signup-content label {
  color: #fff;
  font-weight: 400;
}

.signup-content h2 {
  font-weight: 400;
  font-size: 2.2rem;
  color: #8599ab;
  letter-spacing: 0.293rem;
  text-transform: uppercase;
  padding-bottom: 3.6rem;
  margin-bottom: 3.4rem;
  border-bottom: 1px solid #3b5b79;
  display: block;
  width: 100%;
  text-align: center;
  line-height: 1.1;
}

.signup-email-input,
.signup-password-input {
  margin-top: 1.2rem;
  margin-bottom: 2.2rem;
}

.watch-cancel,
.watch-cancel-step-2 {
  color: #667f96;
  font-size: 1.3rem;
  font-weight: 400;
}

/* MENU, LOGIN & WATCH OVERLAY MEDIA QUERIES */

@media only screen and (min-width: 40em) {
  .login-overlay,
  .watch-overlay,
  .watch-overlay-step-2,
  .signup-overlay {
    padding-top: 150px;
  }
}


/* SEARCH PAGE FILTERS SLIDE IN */

/* FOR MARKETERS INTRO */

.marketers-intro .content-padding {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.marketers-intro .text-content {
  width: 54.5rem;
  max-width: 100%;
}

.marketers-intro .text-content h3 {
  margin-top: 5.8rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 2.3rem;
}

.marketers-intro .image {
  width: 49.9rem;
  max-width: calc(100% + 110px);
  overflow: hidden;
  order: -1;
  margin-left: -31px;
  margin-top: -24%;
  margin-bottom: 2rem;
}

.marketers-intro .image img {
  width: 100%;
  height: auto;
}

.marketers-intro .marketing-usages {
  display: flex;
  flex-wrap: wrap;
}

.marketers-intro .marketing-usages a {
  font-weight: 600;
  font-size: 1.3rem;
  color: #0b3358;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
  width: 100%;
  margin-bottom: 1.6rem;
  display: flex;
}

.marketers-intro .marketing-usages.small-width a {
  width: 50%;
}

.marketers-intro .marketing-usages a:before {
  content: "";
  width: 18px;
  height: 18px;
  align-self: center;
  background-size: 18px 18px;
  background-image: url("../images/arrow-down-circle.svg");
  background-repeat: no-repeat;
  margin-top: -0.2rem;
  margin-right: 1rem;
}

/* FOR MARKETERS INTRO MEDIA QUERIES */

@media only screen and (min-width: 35em) {
  .marketers-intro .image {
    margin: 0 0 2rem 0;
  }
}

@media only screen and (min-width: 52em) {
  .marketers-intro .content-padding {
    flex-direction: row;
  }
  .marketers-intro .text-content {
    width: 54.5rem;
  }
  .marketers-intro .image {
    width: 43.771929824%;
    order: 2;
    margin: 0 0 0 4rem;
  }
  .marketers-intro .marketing-usages a {
    width: 50%;
  }
  .marketers-intro .marketing-usages.small-width a {
    width: 40%;
  }
}