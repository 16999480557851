@import '../../framework/styleguide.scss';

.search-page-filter-bar {
    position: fixed;
    top: 0;
    left: -320px;
    width: 320px;
    max-width: 100%;
    height: 100%;
    background-color: $off-white;
    box-shadow: none;
    z-index: 101;
    -webkit-transition: left 0.5s ease, box-shadow 0.8s ease;
    -moz-transition: left 0.5s ease, box-shadow 0.8s ease;
    -ms-transition: left 0.5s ease, box-shadow 0.8s ease;
    -o-transition: left 0.5s ease, box-shadow 0.8s ease;
    transition: left 0.5s ease, box-shadow 0.8s ease;

    &--is-open {
        left: 0;
        box-shadow: 0 0 60px 0 rgba($black, 0.5);
    }

    &__scroll {
        height:100vh;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $white;
        border-bottom: 1px solid $very-light-pink-three;

        h3 {
            font-weight: 600;
            font-size: 1.8rem;
            color: $dabas-blue;
            padding-left: 18px;
            text-transform: uppercase;
        }
    }

    &__close {
        padding: 30px 20px;

        &:hover {
            cursor: pointer;
        }
    }

    &__footer {
        bottom: 0;
        box-shadow:0 0px 4px 0 rgba(0, 0, 0, 0.3);
        width: 100%;
        background-color: $off-white;
        text-align: center;
        padding-bottom: 20px;
        position: absolute;
    }

    &__apply-filters {
        width: 90%;
        margin: 2.1rem 0 1.3rem 0;
        align-self: center;
        position: relative;
        transition: all 0.3s ease;
    }

    &__apply-filters-text {
        opacity: 1;
        transition: opacity 0.3s ease-in-out;
        .search-page-filter-bar--is-loading & {
            opacity: 0;
        }

    }
}