@import '../../framework/styleguide.scss';
@import '../../framework/common.scss';
@import '../../framework/helpers.scss';
.news {
    background-color: $off-white;
    min-height:calc(100vh - 60px);
    padding-bottom: 100px;

    @include breakpoint(large) {
        min-height:calc(100vh - 90px);
    }

    &__header {
        @include fit-content();
    }

    &__header-content {
        @include content-padding();
        padding-bottom: 0;

        @include breakpoint(large) {
            padding-bottom: 0;
        }
    }

    &__footer {
        @include fit-content(); 
    }

    &__footer-content {
        @include content-padding();
        padding-top: 0;

        @include breakpoint(large) {
            padding-top: 0;
        }
    }
}