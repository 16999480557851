@import '../../framework/styleguide.scss';
.newsroom-footer {
    background-color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 7.4rem;

    img {
        width: 118px;
        height: auto;
        max-width: 90%;
        margin-bottom: 1.6rem;
    }

    p {
        color: $dabas-blue;
    }

    a {
        color: $dabas-blue;
        text-decoration: underline;
    }
}
  