@import '../../framework/common.scss';

.search-story {
    background-color: #fff;
    margin:0 1% 3.1rem 1%;
    padding:20px;
    position: relative;
    width: 100%;
    z-index: 1;

    @include breakpoint(large) {
        display: flex;
        flex: 0 0 48.17982456199999%;
        margin: 0 0 3.1rem 0.964912281%;
    }

    &:before {
        border: solid 20px #fdf0e6;
        bottom: 20px;
        content: "";
        left: 20px;
        position: absolute;
        right: 20px;
        top: 20px;
        background-color: #fdf0e6;

        @include breakpoint(large) {
            bottom: 40px;
            left: 60px;
            top: 60px;
        }
    }

    &--no-image {
        &:before {
            bottom: 20px;
            left:20px;
            top: 20px;
        }
    }

    &__content {
        background-color: #fdf0e6;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        position: relative;
        z-index: 2;
        margin-left: auto;
        margin-right: auto;
        padding:20px 20px 0 20px;
        top:-40px;
        width:calc(100% - 40px);

        @include breakpoint(large) {
            
            
            background-color: transparent;
            top:0;
            /*position: absolute;
            top: 20px;
            bottom: 20px;
            left: 20px;
            right: 20px;*/
            padding:0;
            width: 100%;
        }
    }

    &__image {
        background-size: cover;
        display: block;
        height: 200px;
        position: relative;
        width: 100%;
        background-position: center center;

        @include breakpoint(large) {
            height: calc(62% - 20px);
            left: 20px;
            position: absolute;
            top: 20px;
            width: calc(50% - 20px);
        }
    }

    &__heading {
        font-family: 'Noto Serif Display', serif;
        font-size: 2.8rem;
        line-height: 1.2;
        margin-bottom: 10px;
        width: 100%;

        @include breakpoint(large) {
            margin-bottom: 16px;
            margin-left: auto;
            margin-top: 20px;
            padding-right: 25px;
            width: 48%;
        }

        .search-story--no-image & {
            margin-left: 40px;
            margin-right: auto;
            margin-top: 40px;
            width: 60%;
        }

        a {
            color:#0b3358;
            text-decoration: none;
        }
    }

    &__text {
        width: 100%;

        @include breakpoint(large) {
            margin-bottom: 16px;
            margin-left: auto;
            width: 48%;
        }

        .search-story--no-image & {
            margin-left: 60px;
            width: 70%;
        }

        p {
            font-weight: 300;
            margin-bottom: 20px;
            font-size: 1.3rem;
            line-height: 1.6;
            padding-right: 25px;

            @include breakpoint(large) {
                margin-bottom: 0;
            }

            a {
                color:#222;
                display: block;
                text-decoration: none;
            }
        }
    }

    &__products {
        display: flex;
        justify-content: center;
        margin-top: auto;
        margin-left: -30px;
        margin-right: -30px;
        position: relative;
        width: calc(100% + 60px);
        z-index: 10;

        @include breakpoint(large) {
            margin-left: -10px;
            margin-right: -10px;
            width:calc(100% + 20px);
        }

        .search-story--no-image & {
            margin-bottom: 30px;
        }
    }

    &__product {
        padding: 0 10px;
        width: 33.333%;

        a {
            display: flex;
            flex-direction: column;
            height: 100%;
            text-align: center;
            text-decoration: none;
        }
    }

    .news-list__item-logo {
        position: absolute;
        z-index: 2;
        width: 94px;
        height: 33px;
        left: 30px;
        top: 15px;  

        @include breakpoint(large) {
            top: 30px; 
        }
    }

    &--no-image {
        .news-list__item-logo {
            left:auto;
            right:30px;
        }
    }

    &__product-heading {
        font-size: 1.3rem;
        line-height: 1.2;
        margin-bottom: 3px;
    }

    &__product-subheading {
        color: #222;
        font-size: 1.2rem;
        font-weight: 300;
        line-height: 1.2;
        margin-bottom: 6px;
    }

    &__product-image {
        background-color:#fff;
        border-radius: 50%;
        border: 6px solid #fff;
        box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.47);
        cursor: pointer;
        height: 56px;
        margin: auto auto 0;
        overflow: hidden;
        position: relative;
        text-align: left;
        width: 56px;

        img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}



/*background-color: #fdf0e6;*/