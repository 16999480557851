@import './common.scss';
@import './styleguide.scss';
@mixin content-padding {
    padding: 57px 20px;
    overflow: hidden;
    @include breakpoint(medium) {
        padding: 86px 10.416666667%;
    }
}

@mixin fit-content {
    max-width: 100%;
    margin: 0 auto;

    @include breakpoint(large) {
        width: 1440px;
    }
}

@mixin read-more-link {
    text-transform: uppercase;
    text-decoration: none;
    font-size: 1.3rem;
    font-weight: 600;
    color: $dabas-blue;
    letter-spacing: 1px;
    position: relative;
    padding: 0 1px 7px 1px;
    display: inline-block;
      
    &:after {
        border-bottom: 2px solid $reddish-orange;
        content: "";
        position: absolute;
        width: 100%;
        height: 0;
        left: 0;
        bottom: 0px;
        -webkit-transition: bottom 0.1s ease-in-out, color 0.1s ease-in-out;
        -moz-transition: bottom 0.1s ease-in-out, color 0.1s ease-in-out;
        -ms-transition: bottom 0.1s ease-in-out, color 0.1s ease-in-out;
        -o-transition: bottom 0.1s ease-in-out, color 0.1s ease-in-out;
        transition: bottom 0.1s ease-in-out, color 0.1s ease-in-out;
    }
      
    &:hover {
        color: $reddish-orange;
    }

    &:hover::after {
        bottom: 5px;
    }
}

.hide {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
  }

@mixin preamble() {
    font-weight: 400;
    font-size: 2rem;
    color: $brown-grey-two;;
    letter-spacing: 0;
    line-height: 3.2rem;
    margin-top: -1.6rem;
    margin-bottom: 0;

    @include breakpoint(xmedium) {
        font-size: 2rem;
        letter-spacing: 0;
        line-height: 3.2rem;
        margin-top: -1.6rem;
    }
}

.form {

    &__textarea {
        border:none;
        border-radius:3px;
    }

    &__input,
    &__textarea,
    &__select {
        transition: all .3s;

        &:focus {
            outline: $darkish-blue auto 1px;
            outline-offset: -2px;
        }

        &--is-error {
            border-width: 1px 1px 1px 10px;
            border-style: solid;
            background: #f8dfdf;
            border-color:rgb(204, 26, 25) rgb(204, 26, 25) rgb(204, 26, 25) rgb(204, 26, 25);

            &:focus {
                outline: none;
            }
        }
    }

    &__submit {
        &--is-error {
            animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
            transform: translate3d(0, 0, 0);
            backface-visibility: hidden;
            perspective: 1000px;
        }
    }

    &__input-errormsg {
        color: $white;
        display: block;
        margin-top: -15px;
        margin-bottom: 25px;
    }
}

@keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }