@import './styleguide.scss';
@import './common.scss';
/* TYPOGRAPHY */
h1,
h2 {
  font-weight: 600;
  color: $dabas-blue;
  line-height: 3.5rem;
  margin-bottom: 2.3rem;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2.4rem;
}

p {
  color: $text-black;
  line-height: 2.6rem;
  margin-bottom: 2.6rem;
}

.ingress {
  font-weight: 400;
  font-size: 2rem;
  color: $brown-grey-two;
  letter-spacing: 0;
  line-height: 3.2rem;
  margin-top: -1.6rem;
  margin-bottom: 0;
}

/* TYOPGRAPHY MEDIA QUERIES */
@include breakpoint(xmedium) {
    h1 {
        font-size: 4rem;
      }
    
      h1,
      h2 {
        line-height: 5rem;
        margin-bottom: 3.4rem;
      }
      h2 {
        font-size: 3.2rem;
      }
      .ingress {
        font-size: 2rem;
        color: $brown-grey-two;
        letter-spacing: 0;
        line-height: 3.2rem;
        margin-top: -1.6rem;
      }
      .breadcrumb-container {
        padding-top: 1.9rem;
        padding-left: 3.4%;
      }
}