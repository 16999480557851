
@import '../../framework/common.scss';
@import '../../framework/helpers.scss';
.product-cards {
  transition: opacity 0.3s ease-in-out;
  opacity: 1;

  /*&--is-loading {
    opacity: 0.2;
  }*/

  &--gray-background {
      background: $off-white;
  }

  &__outer-wrapper {
      @include fit-content();
  }

  &__slider {
      margin-left: -20px;
      margin-right: -20px;
      padding: 2rem 20px 0;
      overflow-x: auto;
      scroll-snap-type: x mandatory;

      @include breakpoint(medium) {
          margin-left:0;
          margin-right:0;
          overflow-x: visible;
          padding: 2rem 0 0;
          scroll-snap-type: unset;
      }
  }

  &__slider-scroll {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      width: calc(76.8% * 4);
      position: relative;
      margin: 0 -2%;

      @include breakpoint(medium) {
          margin: 0 -1.875%;
          flex-wrap: wrap;
          width: 103.75%;    
      }

      @include breakpoint(xxxmedium) {
          margin: 0 -0.964912281%;
          width: 101.929824562%;
      }
  }

  &__heading {
      margin-bottom: 0.3rem;
      position: relative;

      @include breakpoint(xmedium) {
          margin-bottom: 1.3rem;
      }
  }

  &__wrapper {
      @include content-padding();

      .product-cards--search-page & {
          padding-top:0;
          padding-bottom: 130px;
      }

      .product-cards--newsroom & {
          padding-top: 0;
      }
  }

  &__read-more-link {
      @include  read-more-link();
      margin-top: 3.2rem;
      display: inline-block;
  }

  &__list-view-headings {
    display: none;
  }


  &__list {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 -1%;
    padding-top: 2.6rem;
    position: relative;      
    width: auto;

    @include breakpoint(medium) {
      margin: 0 -1.875%;
      width: 103.75%;
    }

    @include breakpoint(xxxmedium) {
      margin: 0 -0.964912281%;
      width: 101.929824562%;
    }

    &--list-view {
      flex-wrap: nowrap;
      flex-direction: column;
      width: 100%;
      margin-bottom: 7.1rem;

      .product-card {
        display: flex;
        background: transparent;
        box-shadow: none;
        border-radius: 0;
        border-top: 1px solid #d8d8d8;
        width: 100%;
        font-weight: 400;
        letter-spacing: 0;
        margin: 0 1% 0 1% !important;
        text-align: left;
        cursor: pointer;

        &:last-of-type {
          border-bottom: 1px solid #d8d8d8;
        }
      }

      .product-cards__list-view-headings {
        display: flex;
        width: calc(100% - 53px);
        margin: 0 1% 0 calc(1% + 53px);
        padding: 4px 0 30px 0;
        font-weight: 600;
        font-size: 1.3rem;
        color: #999;
        letter-spacing: 1px;
        text-transform: uppercase;
        text-decoration: underline;
      } 
    }
  }

  &__article-category {
    width: calc(20% + 77px);
  }

  &__article-name {
    width: 25%;
  }

  &__manufacturer {
    width: 15%;
  }

  &__art-gtin-wrapper {
    width: 40%;
    display: flex;

    div {
      &:first-child {
        width:35%;
      }

      &:last-child {
        margin-left: auto;
      }
    }
    
  }

  &__load-more {
    .spinner {
      left:0;
      top:15px;
      transform: none;
      opacity:0;
      transition: all 0.3s ease-in-out;
      span {
        height:12px;
        width:12px;
      }
    }

    .product-cards--is-loading & {
      min-width: 175px;

      .spinner {
        opacity: 1;
      }
    }
  }

  &__load-more-text {
    left: 0;
    opacity: 1;
    transition: all 0.3s ease-in-out;
    transform: translateX(0);
    
    .product-cards--is-loading & {
      opacity:0;
      transform: translateX(65px);
      
    }  
  }

  &__load-more-text-loading {
    left:0;
    opacity: 0;
    position: absolute;
    transition: all 0.3s ease-in-out;
    transform: translateX(0);
    white-space: nowrap;
    
    .product-cards--is-loading & {
      opacity: 1;
      transform: translateX(65px);
    }
  }
}

  
  .product-cards.explore-products h2 {
    margin-bottom: 3.2rem;
  }
  
  .product-cards.explore-products button.load-more {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  }
  
  
  .product-cards.search-page .product-cards-wrapper .list-view-headings {
    display: none;
  }
  
  .product-cards.search-page .product-cards-wrapper {
    flex-wrap: wrap;
    width: 100%;
    padding-top: 2.6rem;
    justify-content: flex-start;
  }
  
  .product-cards.search-page.explore-products .product-cards-wrapper {
    padding-top: 0;
  }
  
  .product-cards.search-page .product-card {
    flex: 0 0 100%;
    margin: 0 2% 3.2rem 2%;
  }