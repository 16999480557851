@import '../../framework/styleguide.scss';
@import '../../framework/common.scss';
.toolbar {
    background-color: $white;
    padding:30px 20px;
    width:100%;

    @include breakpoint(medium) {
        padding:30px 10.416666667%;
    }

    @include breakpoint(large) {
        bottom:0;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.24);
        height:80px;
        left:0;
        padding:20px 104px 20px;
        position: fixed;
        text-align: center;
        right:0;
    }

    &__inner {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        width:100%;

        @include breakpoint(large) {
            flex-direction: row;
            height:40px;
            max-width: 1440px;
            margin: 0 auto;
            /*position: relative;*/
        }
    }

    @media print {
        display: none;
    }

    &__logo {
        background-image: url('../../images/productshet-logo@3x.png');
        background-size: 100%;
        background-repeat:no-repeat;
        display: block;
        height: 66px;
        margin: 50px auto 20px;
        width: 150px;

        @include breakpoint(large) {
            bottom:0;
            height:40px;
            margin: 0;
            position: absolute;
            right:30px;
            top:20px;
            width:94px;
        }

        span {
            visibility: hidden;
        }
    }   

    .download {
    
        &__btn {
            display: inline-block;
            margin-bottom: 15px;
            width:100%;
        
            @include breakpoint(large) {
                margin-bottom: 0;
                width: auto;
            }
        }   
    }

}