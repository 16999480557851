@import '../../framework/common.scss';
@import '../../framework/styleguide.scss';

.compare-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: $white;
    box-shadow: 0 0 7px 0 rgba($black, 0.24);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.8rem 1rem;
    z-index: 10;

    @include breakpoint(xmedium) {
        padding: 2rem 0;
    }

    &__link {
        text-decoration: none;
        font-weight: 600;
        margin: 0 1rem;
        font-size: 1.3rem;
        width: 100%;
        text-align: center;
        padding: 0.9rem 1.8rem;

        &:disabled {
            cursor: not-allowed;
            opacity: 0.2;
        }
        
        @include breakpoint(medium) {
            width: auto;
        }

        @include breakpoint(xmedium) {
            font-size: 1.5rem;
        }

        &--outline {
            display: none;

            @include breakpoint(medium) {
                background-color: transparent;
                cursor: pointer;
                font-weight: 400;
                display: inline;
            }
        }
    }
  }