.multipleselect {
    appearance: none;
    display: block;
    font-weight: 400;
    /*color: #9b9b9b;*/
    height: 160px;
    line-height: 1.3;
    padding: 0;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0 0 16px 0;
    border: none;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    border-radius: 3px;
    background-color: #fff;
    background-size: 10px 6px, 100%;
    overflow: auto;

    &__checkbox {
        position: relative;
    }

    input[type=checkbox] {
        & + label {
            align-items: flex-start;
            background-color: transparent;
            border-bottom: 1px solid #ddd;
            height: auto;
            justify-content: start;
            margin-bottom: 0;
            padding: 0.6em 0.5em 0.6em 30px;
            position: relative;
            width:100%;

            &:before {
                left: 10px;
                position: absolute;
                top: 14px;
            }   

            &:after {
                top: 10px;
                left: 15px;
            }
        }

        &:disabled {
            cursor: auto;
            & + label {
                cursor: auto;
                opacity: 0.2;
            }
        }
    }

    input[type=checkbox].multipleselect__checkboxctrl--checked {
        & + label {
            &:after {
                width: 7px;
                height: 14px;
                border-right: 3px solid #e44b14;
                border-bottom: 3px solid #e44b14;
                transform: rotate(45deg);
            }
        }
    }

    &--no-scroll {
        background-color: transparent;
        box-shadow:none;
        height:auto;
        overflow: visible;

        input[type=checkbox] {
            & + label {
                border-bottom: none;
                margin-bottom:5px;
                padding:0 0 0 20px;
                
                &:before {
                    left: 0;
                    position: absolute;
                    top: 5px;
                }   
    
                &:after {
                    top: 0;
                    left: 5px;
                }
            }
        }
    }
}

