@import '../../framework/styleguide.scss';
@import '../../framework/common.scss';
@import '../../framework/helpers.scss';

.blog-items {
    @include fit-content();
    opacity: 1;
    transition: opacity 0.3s ease;

    &--is-loading {
        opacity:0.5;
    }

    &__wrapper {
        @include content-padding();
        padding-top:27px;
        @include breakpoint(xmedium) {
            padding-top: 8.6rem;
        }
    }
    
    &__posts {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: flex-start;
        margin: 0;

        @include breakpoint(medium) {
            margin: 0 -2%;
        }

        @include breakpoint(xmedium) {
            margin: 0 -1.4%;
        }
    }

    &__item {
        width: 100%;
        margin: 0 0 4.4rem 0;
        text-decoration: none;

        &:hover {
            .blog-items__item-heading {
                text-decoration: underline;
            }
        }

        @include breakpoint(medium) {
            margin: 0 2% 4.4rem 2%;
            width: 46%;
        }

        @include breakpoint(xmedium) {
            width: 22.2%;
            margin: 0 1.4% 4.4rem 1.4%;
        }
    }

    &__item-image {
        left:0;
        width: 100%;
        max-width: 100%;
        height: auto;
        max-height: 100%;
        margin-bottom: 1.6rem;
        object-fit: cover;
        position: absolute;
        top:0;

        &--no-image {
            background:rgba($black, 0.05);
            margin-bottom: 1.6rem;
            margin-left:-20px;
            margin-right:-20px;
            padding-top:123.0769230769231%;
            position: relative;

            @include breakpoint(medium) {
                margin-left:0;
                margin-right:0;
            }
        }
    }

    &__item-image-date-day {
        color:rgba($black,0.2);
        display: block;
        font-size: 6rem;
        font-weight: bold;
    }
    &__item-image-date-month {
        color:rgba($black,0.2);
        display: block;
    }

    &__item-image-date {
        position: absolute;
        text-align: center;
        transform: translateY(-50%);
        top:50%;
        width: 100%;
    }

    &__item-date {
        color: $black;
        margin-bottom: 0.2rem;
    }

    &__item-heading {
        font-size: 1.6rem;
        line-height: 1.4;
        margin-bottom: 0;
        color: $dabas-blue;

        &:after {
            content: "";
            display: inline-block;
            margin-left: 0.6rem;
            width: 7px;
            height: 11px;
            background-size: 7px 11px;
            background-image: url("../../images/arrow-right.svg");
            background-repeat: no-repeat;
        }
    }

    &__load-more {
        text-transform: uppercase;
        text-decoration: none;
        font-size: 1.3rem;
        font-weight: 600;
        color: $dabas-blue;
        letter-spacing: 1px;
        position: relative;
        padding: 0 1px 7px 1px;
        display: inline-block;

        &:after {
            border-bottom: 2px solid $reddish-orange;
            content: "";
            position: absolute;
            width: 100%;
            height: 0;
            left: 0;
            bottom: 0px;
            transition: bottom 0.1s ease-in-out, color 0.1s ease-in-out;
        }

        &:hover {
            color: $reddish-orange;;

            &:after {
                bottom: 5px;
            }
        }
    }
  }