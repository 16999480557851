@import '../../framework/helpers.scss';
@import '../../framework/common.scss';

.marketers-info {
    &--gray {
      background: $off-white;
    }
  
    &__wrapper {
        @include fit-content();
    }

    &__wrapper-inner {
        @include content-padding();
        display: flex;
        flex-direction: column;

        @include breakpoint(xmedium) {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &__read-more-link {
        @include read-more-link();
        margin-top: 0.8rem;
    }

    &__info-text,
    .image {
      @include breakpoint(xmedium) {
        width: 46.842105263%;
      }

      &__image {
        width:100%;
      }
    }

    .image {
      order: -1;
      
      @include breakpoint(xmedium) {
        order: -1;
      }
    }
    
    &--img-right {
      .image {
        @include breakpoint(xmedium) {
          order: 1;
        }
      }
    }

    p {
        width: 45rem;
        max-width: 100%;
    }

    h2 {
        margin-top: 0.8rem;
        margin-bottom: 1.2rem;
    }
}