@import '../../framework/common.scss';
@import '../../framework/styleguide.scss';
.story {
    background-color: #fff;
    margin-bottom:57px;
    padding:0;
    position: relative;

    .is-preview & {
        margin-bottom:20px;
        padding:0 20px;
        
    }

    @include breakpoint(large) {
        padding:0 80px;
        margin-bottom:85px;

        .is-preview & {
            margin-bottom:20px;
            padding:0 20px;
            
        }
    }

    &__container {
        position: relative;
        width:100%;

        .is-preview & {
            min-width: 1280px;
        }

        &:before {
            background-color: #fdf0e6;
            bottom:0;
            content: '';
            left:0;
            position: absolute;
            right:0;
            top:250px;
        }
    }

    &__container-inner {
        padding: 0 0 110px;
        position: relative;
        z-index: 2;

        @include breakpoint(large) {
            padding: 86px 10.416666667% 0;

            .is-preview & {
                padding: 86px 40px 0;
            }
        }
    }

    &__image {
        background-size: cover;
        background-position: center center;
        height:400px;
        width:100%;
        
        @include breakpoint(large) {
            height:600px;
            margin-bottom: 85px;
            position: relative;
            width:50%;
        }
    }

    &__top {
        display: flex;
        flex-direction: column;

        @include breakpoint(large) {
            flex-direction: row;
        }
    }

    &__heading {
        font-family: 'Noto Serif Display', serif;
        font-size: 3.6rem;
        line-height: 1;
        margin-bottom: 20px;
        margin-right: auto;
        position: relative;
        z-index: 10;
        text-align: left;
        top:0;

        @include breakpoint(large) {
            font-size: 5.6rem;
            left:40px;
            margin-bottom: 40px;
            margin-top:90px;
            max-width: 75%;
        }
    }

    &__text {
        align-self: center;
        background-color: #fdf0e6;
        margin-top: -80px;
        padding: 25px 25px 0 25px;
        position: relative;
        width: calc(100% - 40px);
        

        @include breakpoint(large) {
            background-color: transparent;
            margin-top: 0;
            padding:0;    
            width:50%;
        }
    }

    &__preamble {
        @include breakpoint(large) {
            padding-left:80px;
            padding-right: 0;
        }

        p {
            color:$text-black;
            line-height: 1.4;
            font-size: 1.6rem;
            font-weight: 300;
            margin-bottom:20px;
            margin-top:0;

            @include breakpoint(large) {
                font-size: 2.2rem;
            }
        }
    }

    &__date {
        background-image: url('../../images/calendar.svg');
        background-repeat: no-repeat;
        background-position: 0px center;
        padding-left:22px;
        padding-right: 0;
        font-weight: 300;

        @include breakpoint(large) {
            background-position: 80px center;
            padding-left: 104px;
        }
    }

    &__body {
        p {
            font-size: 1.4rem;
            line-height: 1.4;
            margin-bottom:2rem;
            padding-left:0;
            padding-right: 0;

            @include breakpoint(large) {
                font-size: 1.8rem;
                margin-bottom: 2.6rem;
                padding-left:80px;
            }
        }

        ul, ol {
            font-size: 1.4rem;
            padding-left:0;
            padding-right:0;

            @include breakpoint(large) {
                font-size: 1.8rem;
                margin-bottom: 2.6rem;
                padding-left: 130px;
            }
        }

        strong {
            font-weight: bold;
        }

        em {
            font-style: italic;
        }
    }

    &__author {
        font-style: italic;
        font-weight: 300;
        padding-left: 0;

        @include breakpoint(large) {
            padding-left: 80px;
        }
    }

    &__products {
        display: flex;
        margin-top:30px;
        padding: 20px 0 57px;
        position: relative;

        @include breakpoint(large) {
            padding: 0 10.416666667% 66px;
        }
    }

    &__products-heding-container {
        width:100%;
    }

    &__products-container {
        display: flex;
        flex-wrap: wrap;
        width:100%;

        @include breakpoint(large) {
            margin-left: -40px;
            margin-right: -40px;
            padding-top:20px;
            position: relative;
            width: calc(100% + 80px);

            &:before {
                content: "";
                width: calc(100% - 80px);
                height: 1px;
                position: absolute;
                top: 0;
                background-color: #d8d8d8;
                margin-left: 40px;
                margin-right: auto;
            }
        }
    }

    &__product {
        padding:20px 45px;
        width: 100%;
        
        @include breakpoint(large) {
            width: 33.3333%;
            padding:20px 40px;
        }

        a {
            text-align: left;
            text-decoration: none;
        }

        p {
            border-top:1px solid #d8d8d8;
            font-weight: 300;
            padding-top: 12px;
            text-align: left;
            font-size: 1.4rem;

            @include breakpoint(large) {
                font-size: 1.6rem;
            }
            
        }
    }

    &__product-heading {
        font-size: 1.5rem;
        line-height: 1.2;
        margin-bottom: 6px;
        text-align: center;
    }

    &__product-subheading {
        color: #222;
        font-size: 1.5rem;
        font-weight: 300;
        line-height: 1.2;
        margin-bottom: 4px;

        strong {
            font-weight: bold;
        }

        &--center {
            margin-bottom:20px;
            text-align: center;

            @include breakpoint(large) {
                margin-bottom:30px;
            }
        }

        &--small {
            font-size: 1.3rem;
            margin-bottom:6px;

            @include breakpoint(large) {
                margin-bottom:6px;
            }
        }

        &--data {
            border-top:1px solid #d8d8d8;
            display: flex;
            font-size: 1.4rem;
            margin-bottom: 0;
            padding:6px 0;

            span {
                margin-left: auto;
            }
        }
    }

    &__products-heading {
        font-family: 'Noto Serif Display', serif;
        font-size: 5.6rem;
        text-align: center;
    }

    &__product-image {
        background-color: #fff;
        border-radius: 50%;
        border: 6px solid #fff;
        box-shadow: 0 0 7px 0 rgb(0, 0, 0, 0.47);
        cursor: pointer;
        height: 106px;
        margin: auto auto 20px;
        overflow: hidden;
        position: relative;
        text-align: left;
        width: 106px;

        @include breakpoint(large) {
            height: 180px;
            margin: auto auto 30px;
            width: 180px;
        }


        /*cursor: pointer;
        height: 400px;
        margin: auto auto 14px;
        overflow: hidden;
        position: relative;
        text-align: left;
        width: 100%;*/

        img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    /*&__product-heading {
        font-size: 1.5rem;
        line-height: 1.2;
        margin-bottom: 6px;
    }*/

    .news-list__item-logo {
        left:20px;
        position: absolute;
        top:20px;
    }

    .post__share {
        position: absolute;
        bottom: 30px;

        @include breakpoint(large) {
            bottom: -62px;
        } 
    }

    .post__share__content {
        margin-left:45px;

        @include breakpoint(large) {
            margin-left:0;
        }
    }
}