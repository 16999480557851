@import '../../framework/styleguide.scss';
@import '../../framework/common.scss';

.story-push {
    
    max-width: 100%;
    padding:80px 0 0;

    &--no-margin-top {
        padding:0;

        @include breakpoint(large) {
            padding:80px 0 0;
        }
    }
    
    &__slider-container {
        padding-bottom: 80px;
        position: relative;
        overflow: hidden;
    
        &:after {
            background-color: #fdf0e6;
            bottom:0;
            content:'';
            height:100%;
            position: absolute;
            top:100px;
            width:100%;
        }
    }

    &__slider {
        display: flex;
        flex-direction: row;
        position: relative;
        transition: all 0.8s ease;
        z-index: 10;
    }

    &__slider-item {
        flex-shrink: 0;
        position: relative;
        width:100%;
        z-index: 10;
        /*left: -50%;
        transition: all 0.3s ease;
        transform: translateX(30%);
        z-index: 10;

        &--selected {
            left: 0;
            transform: translateX(0);
        }*/
    }

    &__container {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        max-width: 1140px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        width:100%;
        z-index: 10;

        @include breakpoint(large) {
            flex-direction: row;
        }
    }

    &__tagline {
        max-width: 500px;
        margin: 0 auto;
        text-align: center;
        width: 62.2rem;
        
        h1 {
            font-size: 3.2rem;
            font-weight: 300;
            margin:0 0 60px;
        }

        strong {
            font-weight: normal;
        }
    }

    &__image {
        height: 400px;
        width: 100%;
        position: relative;
        z-index: 2;

        @include breakpoint(large) {
            height: 600px;
            width: 40%;
        }
    }

    &__image-link {
        background-size: cover;
        background-position: center center;
        bottom:0;
        display: block;
        left: 0;
        position: absolute;
        right:0;
        top:0;
    }

    &__content {
        background-color: #fdf0e6;
        align-self: center;
        display: flex;
        flex-direction: column;
        padding: 25px 25px 0 25px;
        width: calc(100% - 40px);
        margin-top: -80px;
        position: relative;
        z-index: 2;

        @include breakpoint(large) {
            background-color: transparent;
            margin-top: 0;
            padding:60px 100px 0 60px;
            width: 60%;
        }
    }

    &__heading {
        font-family: 'Noto Serif Display', serif;
        font-size: 3.6rem;
        line-height: 1;
        margin-bottom: 20px;
        margin-right: auto;
        max-width: 95%;
        position: relative;
        z-index: 10;
        text-align: left;
        top:0;

        @include breakpoint(large) {
            left:-25px;
            font-size: 5.6rem;
            margin-bottom: 40px;
        }

        a {
            color:$dabas-blue;
            text-decoration: none;
        }
    }

    &__text {
        font-size: 1.6rem;
        font-weight:300;
        margin-bottom: 30px;
        max-width: 660px;
        position: relative;
        z-index: 10;

        @include breakpoint(large) {
            font-size: 1.8rem;
            margin-bottom: 40px;
        }
        
        & p {
            
            margin-bottom: 0;
        }

        & a {
            color:#222;
            text-decoration: none;
        }
    }

    &__products {
        display: flex;
        /*margin-top:auto;*/
        margin-left: -25px;
        margin-right: -25px;
        position: relative;
        z-index: 10;

        @include breakpoint(large) {
            margin-left: -10px;
            margin-right: -10px;
        }
    }
    
    &__product {
        padding:0 10px;
        width: 33.333%;

        a {
            display: flex;
            flex-direction: column;
            height: 100%;
            text-align: center;
            text-decoration: none;
        }
    }

    &__product-heading {
        font-size: 1.3rem;
        line-height: 1.2;
        margin-bottom: 6px;

        @include breakpoint(large) {
            font-size: 1.5rem;
        }
    }
    &__product-subheading {
        color:#222;
        font-size: 1.3rem;
        font-weight: 300;
        line-height: 1.2;
        margin-bottom: 12px;

        @include breakpoint(large) {
            font-size: 1.5rem;
        }

        &--small {
            font-size: 1.3rem;
            margin-bottom: 6px;

            @include breakpoint(large) {
                font-size: 1.3rem;
            }
        }
    }

    &__product-image {
        border-radius: 50%;
        border: 6px solid #fff;
        box-shadow: 0 0 7px 0 rgba(0,0,0,0.47);
        cursor: pointer;
        height: 76px;
        margin: auto auto 14px;
        overflow: hidden;
        position: relative;
        text-align: left;
        width: 76px;

        @include breakpoint(large) {
            height: 86px;
            width: 86px;
        }

        img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    
    &__paginate {
        display: flex;
        justify-content: center;
        position: absolute;
        bottom:30px;
        list-style: none;
        z-index: 11;
        width: 100%;
    }

    &__paginate-item {
        background-color: transparent;
        border-radius: 50%;
        border:solid 2px #e44b14;
        cursor: pointer;
        display: block;
        height:20px;
        margin-left:3px;
        margin-right:3px;
        position: relative;
        width:20px;

        &:after {
            background-color: #e44b14;
            border-radius: 50%;
            content: '';
            height:12px;
            left:0;
            margin:2px;
            position: absolute;
            top:0;
            transition: all 0.5s ease;
            transform: scale(0);
            width:12px;

        }

        &--selected {
            &:after {
                transform: scale(1);
            }
        }
    }

    .news-list__item-logo {
        position: absolute;
        z-index: 10;
        right: 30px;
        top: 30px;

        @include breakpoint(large) {
            left: 30px;
            right: auto;
        }
    }
    
}