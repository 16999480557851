@import '../../framework/common.scss';
@import '../../framework/styleguide.scss';
/* MY WATCHED ITEMS */

.my-watched-items .title-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 3.8rem;

    @include breakpoint(medium) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
  }
  
  .my-watched-items .title-wrapper h1 {
    margin-bottom: 1.2rem;
  }
  
  .my-watched-items .title-wrapper a {
    color: #0b3358;
    text-decoration: none;
  }
  
  .my-watched-items .options {
    border-top: 1px solid #e4e4e4;
    opacity: 1;
    min-height: 50vh;
    position: relative;

    .options-inner {
        transition: all 0.3s ease;
        opacity: 1;
    }

    .spinner {
        visibility: hidden;

        span {
            background-color: $dabas-blue;
        }
    }

    &--is-loading {
        .options-inner {
            opacity: 0.2;
        }
        

        .spinner {
            visibility: visible;
            opacity: 1;
        }
    }
  }

  
  
  .my-watched-items .option {
    padding: 2.3rem 0 2.3rem 0;
    border-bottom: 1px solid #e4e4e4;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .my-watched-items .option-info {
    display: flex;
    flex-direction: column;

    @include breakpoint(medium) {
        flex-direction: row;
    }
  }
  
  .my-watched-items .option-info a.option-title {
    text-decoration: none;
    color: #0b3358;
  }
  
  .my-watched-items .option-info a.option-title:hover {
    text-decoration: underline;
  }
  
  .my-watched-items .option-info .new-items,
  .my-watched-items .option-info .no-new-items {
    border-radius: 3px;
    font-weight: 600;
    text-align: center;
    width: 8rem;
    margin-bottom: 0.5rem;

    @include breakpoint(medium) {
        margin-right: 2.8rem;
        margin-bottom: 0;
    }
  }
  
  .my-watched-items .option-info .new-items {
    background-color: #e44b14;
    color: #fff;
  }
  
  .my-watched-items .option-info .no-new-items {
    background-color: #ddd;
  }
  
  .my-watched-items .option-edit .edit-icon {
    background-color: transparent;
    border:none;
    cursor: pointer;
    padding: 2rem 1rem 2rem 2rem;
  }
  
  .my-watched-items .option-edit .delete-icon {
    background-color: transparent;
    border:none;
    cursor: pointer;
    padding: 2rem 0 0 1rem;
  }
  
  .my-watched-items .watch-new-item {
    display: inline-block;
    width: 100%;
    margin-top: 4.2rem;

    @include breakpoint(medium) {
        width: auto;
        margin-top: 6.1rem;
    }
  }