@import '../../framework/helpers.scss';
@import '../../framework/styleguide.scss';

.product-sheet { 
    @include fit-content();
    &__content {
        @include content-padding();
        padding-bottom:0;

        @include breakpoint(medium) {
            padding-bottom:0;
        }

        @include breakpoint(large) {
            padding-top:0;
            padding-bottom:0;
        }

        @media print {
            padding-top:50px;
        }
    }

    &--has-logo {
        .product-sheet__content {
            padding-top:140px;

            @include breakpoint(medium) {
                padding-top:140px;
            }

            @include breakpoint(large) {
                padding-top:100px;
            }
        }
    }

    &__header {
        background: $white;
        box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.15);
        height:80px;
        left:0;
        padding:14px 0;
        position: absolute;
        top:0;
        width:100%;

        @include breakpoint(large) {
            height:90px;
        }

       @media print {
           padding: 0 0 14px;
       }

        img {
            display: block;
            margin:0 auto;
            max-height:60px;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &__footer {
        background: $off-white;
        border-radius: 3px;
        padding:28px 28px 10px 28px;

        @include breakpoint(large) {
            padding:28px 28px 100px 28px;
        } 

        @media print {
            font-size: 9pt;
            page-break-inside: avoid;
            padding:28px;
        }

        a {
            color:$peacock-blue;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        &-top {

            @include breakpoint(large) {
                align-content: flex-start;
                display: flex;
                flex-wrap: wrap;
            }
        }

        &-bottom {
            border-top:solid 1px $very-light-pink;
            padding:30px 0;
            text-align: center;
            
            a {
                text-decoration: underline;
            }

            @media print {
                display: none;
            }
        }

        &-container-heading {
            width:100%;
        }

        &-heading {
            color:$text-black;
            font-size: 2.4rem;

            @media print {
                font-size: 12pt;
            }
        }
        
        &-subheading {
            color:$brown-grey-two;
            display: block;
            font-size: 1.2rem;
            font-weight: bold;  
            letter-spacing: 1.2px;
            text-transform: uppercase;

            @media print {
                font-size: 7pt;
            }
        }

        &-content {
            
            &--right {
                border-top:1px $very-light-pink solid;
                padding:10px 0;
                width: 100%;
            }
            
            @include breakpoint(large) {
                align-content: flex-start;
                display: flex;
                flex-wrap: wrap;
                width:75%;
    
                &--right {
                    border-top:none;
                    display:block;
                    padding: 0;
                    position: relative;
                    text-align: right;
                    width:25%;
                }
            }
        }

        &-content-box {
            border-top:1px $very-light-pink solid;
            padding:10px 0;

            @include breakpoint(large) {
                border-top:none;
                padding: 0 10px 30px 0;
            }
            
            @media print {
                padding: 0 10px 15px 0;
            }
        }
        
        &-content-col {
            width:100%;

            @include breakpoint(large) {
                width:20%;

                &--last {
                    width:60%;
                }
            }
        }

        &-logo {
            background:$white;
            border-radius: 3px;
            box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.33);
            display: none;
            float: right;
            margin-bottom:87px;
            padding:10px;

            @include breakpoint(large) {
                display: block;
            }

            @media print {
                margin-bottom:40px;
            }

            img {
                max-height: 64px;
            }
        }

        &-url {
            @include breakpoint(large) {
                bottom:30px;
                clear: both;
                display: block;
                float: right;
                position: absolute;
                right: 0;
            }
        }        
    }

    &__heading {
        color: $text-black;  
        text-align: center;

        @include breakpoint(large) {
            text-align: left;
        }
    }

    &__subheading {
        color: $text-black; 
        margin-bottom: 8px;
        font-size: 1.8rem;
        font-weight: 300;
        line-height: 1;
        text-align: center;

        @include breakpoint(large) {
            text-align: left;
        }
    }

    &__generic-container {
        @include breakpoint(large) {
            border-bottom:3px solid $very-light-pink;
            float:left;
            margin-bottom: 30px;
            padding-bottom:80px;
            width:100%;
            .product-sheet__heading-conatiner {
                float: left;
                width:50%;
            }

            .product-sheet__image {
                float: right;
            }
        }

       @media print {

            padding-bottom:20px;
       } 
    }

    &__image {
        border-bottom:1px solid $very-light-pink;
        margin-bottom: 30px;
        padding-bottom: 50px;

        @include breakpoint(large) {
            border-bottom:none;
            margin-bottom:0;
            padding-bottom:0;
            width:50%;
        }

        @media print {
            width:35%;
        }

    &-big {
        margin-bottom: 40px;

        @include breakpoint(large) {
            min-height: 100px;
        }

        @media print {
            min-height: 50px;
        }

        &-container {
            margin:0 auto;
            max-width:80%;
            max-height: 500px;

            span {
                display: block;
                position: relative;
            }
        }

        img {
            display: block;
            margin:0 auto;
            max-width:100%;
            max-height: 500px;

            @media print {
                max-width: 100%;
            }

            .product-sheet__image--no-image & {
                @include breakpoint(large) {
                    margin:100px auto;   
                }

                @media print {
                    margin:50px auto; 
                }
            }
        }
    }

    

    &-gallery {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        &-image {
            border-radius:50%;
            border:6px solid $white;
            box-shadow: 0 0 7px 0 rgba($black, 0.47);
            cursor: pointer;
            height:56px;
            margin:0 7px 14px;
            overflow: hidden;
            position: relative;
            width:56px;

            img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    }

    &__image-download {
        background-color: $text-black;
        background-image: url("../../images/download.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 16px;
        bottom: 20px;
        display: block;
        height: 30px;
        position: absolute;
        right: 20px;
        width: 30px;

        span {
            position: absolute;
            visibility: hidden;
        }
    }

    &__table {
        border-collapse: collapse;
        font-size: 1.6rem;
        margin-top:20px;
        margin-bottom: 20px;
        width:100%;

        & + .product-sheet__table {
            margin-top:50px;
        }

        @media print {
            font-size: 10pt;
        }

        tr {
            border-top: 1px solid $very-light-pink;

            &.product-sheet__table-footer {
                background: $white;
            }
        }

        th {
            font-weight: 600;
            padding:12px 16px 12px 0;
            text-align: left;
            width:35%;
        }
        
        td {
            padding:12px 0 12px 16px;
        }

        a {
            color:$peacock-blue;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        &--striped {
            tr {
                border-top:none;
                border-bottom: none;
                &:nth-child(odd) {
                    background: $off-white;
                }

                &:last-child {
                    border-bottom:none;
                }
            }

        

            th {
                width:50%;
                padding:12px 16px;
            }

            td {
                padding:12px 16px;
                white-space: normal;

                ul,
                ol {
                    margin: 0 0 20px 20px;
                }
            }
        }

        &--33 {
            th {
                width:33%;
            }
        }

        &--margin-top {
            margin-top:20px;
        }
        
        &--column {
            thead {
                tr {
                    background: $off-white; 
                }
            }
            tbody {
                tr {
                    &:nth-child(odd) {
                        background: $white;
                    }
        
                    &:nth-child(even) {
                        background: $off-white;
                    }
                }
                
            }
        }

        &--align-right {
            td {
                text-align: right;
            }

            thead {
                th:last-child {
                    padding-right: 0;
                    text-align: right;
                }
            }
        }

        &--full-width {
            td {
                text-align: left;
                padding-left:0;
                padding-right: 16px;

                &:last-child {
                    padding-right: 0;
                    text-align: right;
                }
            }

            thead {
                th {
                    white-space: nowrap;
                }

                th:last-child {
                    padding-right: 0;
                    text-align: right;
                }
            }
        }

        &-list {
        list-style: none;
        
        li {
            display: block;
        }
        }

        &--widthauto {
            th {
                width: auto;
                word-break: break-all;
            }
        }

        &--uhm {
            table-layout: fixed;
            margin: 0;
            th {
                word-break: normal;
            }
        }
    }

    &__general {
        align-content: flex-start;
        border-bottom: 1px solid $very-light-pink;
        display: flex;
        margin-bottom:30px;
        flex-wrap: wrap;

        @include breakpoint(large) {
            border-bottom: none;
            margin-bottom:0;
            width:35%;
        }

        @media print {
            font-size: 10pt;
            width:50%;
        }

        &--wide {
            border-bottom: none;
            margin-top:20px;
            margin-bottom: 0;
            @include breakpoint(large) {
                width: 100%;
            }

            @media print {
                width: 100;
            }

            .product-sheet__general-data {
                width:50%;
                @include breakpoint(large) {
                    width:25%;
                }       
            }
        }

        &-data {
            padding:0 10px 30px 0;
            width:50%;

            a {
                color:$peacock-blue;
                text-decoration: underline;
            }
        }

        &-heading {
            color:$brown-grey-two;
            display: block;
            font-size: 1.2rem;
            font-weight: bold;  
            letter-spacing: 1.2px;
            text-transform: uppercase;

            @media print {
                font-size: 7pt;
            }
        }

        &-heading-muted {
            font-weight: 300;
        }
    }

    &__newsroom-link {
        background-image: url('../../images/light-blue-megaphone.svg');
        background-repeat: no-repeat;
        background-position: left 5px;
        display: inline-block;
        margin: 0 20px;
        padding-left: 20px;
        background-size: 16px;
    }

    &__all-products-link {
        background-image: url('../../images/search-light-blue.svg');
        background-repeat: no-repeat;
        background-position: left 6px;
        display: inline-block;
        margin: 0 24px;
        padding-left: 20px;
        background-size: 14px;
    }

    &__inner-list {
        list-style: none;

        .product-sheet__table--striped td & {
            margin:0 0 10px 0;
        }
    }
    &__outer-list-item {
        list-style: none;
    }
}

.product-sheet-boxes {
    width: 100%;

    @include breakpoint(large) {
        display: flex;
        flex-wrap: wrap;
        margin-bottom:60px;
    }

    &__col {
        @include breakpoint(large) {
            width:50%;

            &:first-child {
                padding-right:37px;

                @media print {
                    padding-right:15px
                }
            }
    
            &:nth-child(2) {
                padding-left:37px;

                @media print {
                    padding-left:15px
                }
            }
    
            /*.product-sheet-box {
                &:nth-child(2) {
                    .product-sheet-box__inner {
                        border-bottom:none;
                    }
                }
            }*/
        }
        
    }

    /*@media print {
        column-gap: 54px;  
    }*/
}

.product-sheet-box {
    padding-bottom:20px;

    @include breakpoint(large) {
        padding-bottom:30px;

        @media print {
            page-break-inside: avoid;
            padding-bottom:15px;
        }

        &--wide {
            clear:both;
            margin-bottom:0;
            padding-bottom:0;
            width:100%;

            .product-sheet-box__inner { 
                padding-top:30px;
            }

            &:first-of-type {
                .product-sheet-box__inner { 
                    border-top:solid 3px $very-light-pink;
                }
            }

            &:last-of-type {
                margin-bottom:70px;

                @media print {
                    margin-bottom:30px;
                }

                .product-sheet-box__inner { 
                    margin-bottom: 0;
                    padding-bottom:30px;
                    border-bottom:solid 3px $very-light-pink;
                }
                
            }
        }

        &--break {
            break-before: column;
        }

        .product-sheet-boxes__col & {
            &:last-child {
                .product-sheet-box__inner { 
                    border-bottom:none;
                }
            }
        }
        
    }

    &--recipes {
        border-bottom:solid 3px $very-light-pink;
        margin-bottom: 30px;        
        position: relative;
        text-align: center;
        width:100%;

        @include breakpoint(large) {
            border-bottom: none;
            border-top:solid 3px $very-light-pink;
            margin-bottom: 0;
            padding-top: 30px;
        }
    }

    &--recipes-is-loading {
        border-top:none;
        margin-top:-45px;
        margin-bottom: -45px;
        width:100%;
    }

    &__inner {
        border-bottom:solid 3px $very-light-pink;
        padding-bottom:30px;

        @media print {
            padding-bottom:15px;
        }

        .product-sheet-box--uhm & {
            padding-bottom:0;
        }

        .product-sheet-box--recipes & {
            border-bottom:none;
        }
    }

    &__content {
        .product-sheet-box--collapsed & {
            display: none;

            @media print {
                display: block;
            }
        }
    }

    &__tablecontent {
        @media print {
            page-break-inside: avoid;
        }
    }
    
    &__heading {
        color:$text-black;
        font-size: 2.4rem;
        font-weight: 600;
        line-height: 1.63;
        margin-bottom: 0;

        @media print {
            font-size: 12pt;
        }

        &-toggle {
            background: transparent;
            border:none;
            cursor: pointer;
            color:$text-black;
            display: block;
            font-size: 2.4rem;
            font-weight: 600;
            line-height: 1.63;
            margin-bottom: 0;
            position: relative;
            text-align: left;
            width: 100%;

            @media print {
                font-size: 12pt;
            }
            

            &:after {
                background:url('../../images/arrow-down-big.svg');
                background-repeat: no-repeat;
                background-position: center center;
                content:'';
                height: 40px;
                position: absolute;
                right:0;
                width: 40px;
                transition: all 0.25s ease;
                transform: rotate(180deg);

                .product-sheet-box--collapsed & {
                    transform: rotate(0deg);
                }

                @media print {
                    display: none;
                }
            }
            
        }
    }

    &__heading-gtin {
        font-size: 1.8rem;
        font-weight: 300;
        position: relative;
        top: -1px
    }

    &__subheading {
        color:$text-black;
        line-height: 1.63;
        font-size: 1.6rem;
        font-weight: 600;
        margin-bottom: 6px;

        @media print {
            font-size: 10pt;
        }
    }

    &__tableheading {
        color:$brown-grey-two;
        display: block;
        font-size: 1.2rem;
        font-weight: bold;  
        letter-spacing: 1.2px;
        margin:40px 0 -10px 16px;
        text-transform: uppercase;

        &--inner {
            margin:24px 0 6px;
        }

        @media print {
            page-break-after: avoid;
        }
    }

    &__list {
        margin:0 0 20px 20px;

        @media print {
            font-size: 10pt;
        }
    }

    &__footer {
        margin-bottom: 20px;
    }

    a {
        color:$peacock-blue;
    }

    p {
        color:$text-black;
        font-size: 1.6rem;
        font-weight: normal;
        line-height: 1.63;

        @media print {
            font-size: 10pt;
        }
    }

    strong {
        font-weight: 600;
    }
}

.allergen__indicator {
    position:relative;

    &:before {
        border-radius: 50%;
        content: '';
        display: inline-block;
        height: 13px;
        width: 13px;
        margin-right: 8px;
    }

    &--contains {
        &:before {
            background: $dusty-red;
        }
    }

    &--freefrom {
        &:before {
            background: #4ea53f;
        }
    }
    
    &--may-contain {
        &:before {
            background: $butterscotch;
        }
    }
    
    .tooltip__icon {
        position: relative;
        top:1;
    }
    
    .tooltip__popup {
        display: block;

        p {
            font-size: 1.2rem;
        }
    }
}

.origin {
    

    &__country {
        background: $off-white;
        border-top-left-radius:3px;
        border-top-right-radius:3px;
        padding:46px 20px 46px 110px;
        position: relative;

        @media print {
            font-size: 10pt;
        }
    }

    &__country-heading {
        font-weight: 600;
        margin:0;
        position: relative;

        @media print {
            font-size: 10pt;
        }
    }

    &__country-flag {
        background: $white;
        border-radius: 50%;
        box-shadow: 0 0 3.5px 0 rgba(0, 0, 0, 0.2);
        height: 66px;
        left: 20px;
        overflow: hidden;
        position: absolute;
        top:38px;
        width: 66px;

        img {
            width: 150%;
            display: inline-block;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &__ingredients {
        border:1px solid rgba($brown-grey, 0.28);
        margin-bottom: 20px;
        padding:30px 20px 0;
    }

    &__ingredients-heading {
        font-weight: 600;

        &--thin {
            font-size: 1.8rem;
            font-weight: 300;
            margin-top:20px;
        }

        @media print {
            font-size: 10pt;
        }
    }

    &__country-heading,
    &__ingredients-heading {
        position: relative;
        .tooltip__icon {
            position: relative;
            top:1;
        }
        .tooltip__popup {
            display: block;

            p {
                font-size: 1.2rem;
            }
        }
    }

    &__ingredients-country {
        display: block;
        margin-bottom: 30px;

        @media print {
            font-size: 10pt;
        }
    }

    &__ursprungstyp {
        display: flex;
        margin-bottom:30px;
        justify-content: flex-start;
        margin-bottom: 10px;
        flex-wrap: wrap;
        padding-right: 20px;

        &--align-left {
            justify-content: flex-start;
        }
    }

    &__ursprungstyp-country {
        text-align: center;
        position: relative;
        /*min-width: 90px;*/
        margin-bottom: 22px;
        padding-bottom: 42px;
        width: 20%;

        &--new-type {
           padding-left: 10px;
           padding-right: 10px;
           &:after {
                background:url('../../images/arrow-right-thin.svg');
                background-repeat: no-repeat;
                bottom: 5px;
                background-size: 100%;
                content: '';
                height: 16px;
                position: absolute;
                width: 18px;
                right: -6px;

                @media (min-width: 1270px) {
                    right: -8px;            
                }
           }

           &:last-child {
               &:after {
                   display: none;
               }
           }
        }
    }

    &__ursprungstyp-heading {
        color:$brownish-grey;
        font-size: 1rem;;
        font-weight: 600;
        text-transform: uppercase;
    }

    &__ursprungstyp-value {
        font-size: 1rem;
    }

    &__ursprungstyp-flag {
        border-radius: 50%;
        bottom:0;
        box-shadow: 0 0 7px 0 rgba($black, 0.2);
        height:32px;
        left: 50%;
        margin:20px auto 0;
        overflow: hidden;
        position: absolute;
        transform: translateX(-50%);
        width:32px;

        img {
            width: 150%;
            display: inline-block;
            position: relative;
            left: 50%;
            -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            transform: translateX(-50%);
            margin-top:-1px;

            &.origin__fishingarea {
                bottom: -7px;
                width: 100%;
            }
        }
    }

    &__ursprungstyp-omrade {
        margin: 0 0 30px;
        display: inline-block;
        width: 100%;

        .origin__ingredients--has-ingredients & {
            border-top:1px solid rgba($brown-grey, 0.28);
            margin: 10px 0 30px;
            padding-top: 30px;
        }
    }
}

.cert-icons {
    display: flex;
    justify-content: left;
    margin:0 0 20px;
    width: 100%;

    &__container {
        margin:0 20px 20px 0;
        position: relative;

        &:hover {
            .cert-icons__popup {
                opacity: 1;
                transform: translateX(-50%) translateY(0);
            }
        }
    }

    &__popup {
        background: $text-black;
        border-radius: 3px;
        box-shadow: 0 0 7px 0 rgba($black, 0.16);
        color:$white;
        display:block;
        left:50%;
        opacity: 0;
        padding:3px 10px;
        position: absolute;
        transition: all 0.25s ease;
        transform: translateX(-50%) translateY(50px);
        top:-50px;
        white-space: nowrap;

        &:before {
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid $text-black;
            top:100%;
            content:'';
            height: 0; 
            left:50%;
            transform: translateX(-50%);
            position: absolute;
            width: 0; 
        }
    }

    &__icon {
        box-shadow: 0 0 7px 0 rgba($black, 0.16);
        border-radius: 50%;
        height: 48px;
        overflow: hidden;
        width: 48px;

        @media print {
            height: 38px;
            width: 38px;
        }

        img {
            width: 100%;

            &.marine_stewardship_council_label {
                top: 50%;
                position: relative;
                transform: translateY(-50%) translateX(-50%);
                max-width: 90%;
                left: 50%;   
            }
        }
    }
}

.download {
    position: relative;
    width:100%;
    @include breakpoint(large) {
        width: auto;
    }
    &__btn {
        background:$text-black;
        border-color:$text-black;
        background-image: url(../../images/download.svg);
        background-repeat: no-repeat;
        background-position: 16px center;
        border-radius: 3px;
        border:none;
        color:$white;
        cursor: pointer;
        display: block;
        font-size: 1.6rem;
        font-weight: normal;
        margin-bottom: 40px;
        padding:14px 14px 14px 50px;
        position: relative;
        text-decoration: none;
        transition: box-shadow 0.2s ease;
        width: 100%;
    
        &-text {
            opacity: 1;
            transition: opacity 0.2s ease;
    
            .download__btn--is-loading & {
                opacity: 0;
            }
        }
    
        .spinner {
            left:calc(50% + 15px);
            opacity: 0;
            transition: opacity 0.2s ease;
            top:18px;
        }
    
        &--is-loading {
            .spinner {
                opacity: 1;
            }
        }
    
        &--small {
            background-position: 12px center;
            background-size: 16px;
            border:solid 1px $text-black;
            display: inline-block;
            font-size: 1.5rem;
            padding:8px 12px 8px 40px;
            width:auto;
    
            .spinner {
                left: calc(50% + 7px);
                top:11px;
    
                span {
                    width: 14px;
                    height: 14px;
                }
            }
        }
    
        @media print {
            display: none;
        }
    
        &:hover,
        &:focus {
            box-shadow: 0 0 16px 0 rgba($black, 0.4);
        }
    
        @include breakpoint(large) {
            margin-bottom:0;
            width:auto;
        }
    }

    &__selector {
        background-color: $white;
        border-radius: 5px;
        bottom:calc(100% + 13px);
        box-shadow: rgba(35, 47, 53, 0.14) 0px 2px 8px 6px;
        display: block;
        opacity: 0;
        padding:0 10px;
        position: absolute;
        transform: translateY(40px);
        transition: all 0.3s ease;
        visibility: hidden;
        width:100%;

        @include breakpoint(large) {
            width:auto;
        }

        &:after {
            content: "";
            position: absolute;
            bottom: -11px;
            left: calc(50% - 13px);
            width: 0px;
            height: 0px;
            border-left: 13px solid transparent;
            border-right: 13px solid transparent;
            border-top: 13px solid #fff;
        }

        &--is-open {
            opacity: 1;
            transform: translateY(0);
            visibility: visible;
        }
    }

    &__selector-btn {
        background-color: $white;
        background-image: url('../../images/download-black.svg');
        background-repeat: no-repeat;
        background-position: 12px center;
        background-size: 16px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border:none;
        color:$text-black;
        cursor: pointer;
        display:inline-block;
        font-size: 1.5rem;
        font-weight: normal;
        text-align: left;
        width: calc(100% + 20px);
        padding: 10px 10px 10px 35px;
        position: relative;
        border-bottom: solid 1px $very-light-pink;
        margin-left: -10px;
        margin-right:-10px;
        z-index: 2;

        &:last-child {
            border-bottom: none;
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        }

        &:hover {
            background-color: rgba($very-light-pink, 0.3);
        }

        &:focus,
        &:active {
            background-color: rgba($very-light-pink, 0.6);
        }
    }
}