@import '../../framework/styleguide.scss';
@import '../../framework/common.scss';
@import '../../framework/helpers.scss';

.search-and-explore {
    background:$off-white;

    &__wrapper {
        @include fit-content(); 
    }

    &__wrapper-inner {
        padding: 57px 20px 0;

        @include breakpoint(medium) {
            padding: 86px 10.416666667% 0;
        }
    }

    &__heading-link {
        color:$darkish-blue;
        display: inline-block;
        font-size: 2.6rem;
        margin-left: 20px;
        padding-right: 35px;
        position: relative;

        &:after {
            background-image: url('../../images/arrow-down-blue.svg');
            content: '';
            width: 33px;
            height: 33px;
            display: inline-block;
            background-repeat: no-repeat;
            background-size: 22px;
            transform: rotate(-90deg);
            position: absolute;
            right: 0;
            background-position: right;
            top: 14px;
        }
    }
}

.filter-buttons-align-right {
    margin-left: auto;
    display: flex;
    align-content: center;
    flex: 1;
    position: relative;

    @include breakpoint(small) {
        flex:0;
    }
}