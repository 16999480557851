.paginate {
    
    .spinner {
        left:0;
        top:15px;
        transform: none;
        opacity:0;
        transition: all 0.3s ease-in-out;
        span {
        height:12px;
        width:12px;
        }
    }

    &__load-more-text {
        left: 0;
        opacity: 1;
        transition: all 0.3s ease-in-out;
        transform: translateX(0); 
    }
    
    &__load-more-text-loading {
        left:0;
        opacity: 0;
        position: absolute;
        transition: all 0.3s ease-in-out;
        transform: translateX(0);
        white-space: nowrap;
    }

    &--is-loading {
        min-width: 175px;

        .spinner {
            opacity: 1;
        }

        .paginate__load-more-text  {
            opacity:0;
            transform: translateX(65px);
        }

        .paginate__load-more-text-loading {
            opacity: 1;
            transform: translateX(65px);
        }
    }
}