.ingredients {
    display: flex;
    flex-wrap: wrap;
    padding-top: 5px;

    &__ingredient {
        cursor: pointer;
        padding: 4px 8px;
        font-size: 1.4rem;
        margin: 10px 10px 0 0;

        img {
            margin-left: 8px;
        }
    }
}
